import { setupActionLogging } from '@karriereat/action-logger';
import { user } from 'app/user';

if (ACTION_LOGGER_FRONTEND_URL) {
    const options = {
        url: ACTION_LOGGER_FRONTEND_URL,
        uuid: user.getUserUuid(),
    };

    setupActionLogging(options);
}
