import { CONSENT_GROUPS, hasConsent, onConsentChange } from 'util/consent';
import { VisitedJobsHistoryClass } from 'util/client-side-storage/classes/visitedJobsHistoryClass';

/* A guard restricts activation of the storage system.
 * The guard for this system should evaluate to truthy if
 * performance + analysis cookies are allowed by the user */
const guard = () => hasConsent(CONSENT_GROUPS.performance);
// Use this guard to force enable the storage system, e.g. for when k4.test cookies are still broken
// const guard = () => true;

export const VisitedJobsHistory = new VisitedJobsHistoryClass({ guard });

onConsentChange(VisitedJobsHistory.onConsentChange.bind(VisitedJobsHistory));
