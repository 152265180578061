import { AppModule } from 'app/module';
import { checkRule, parseRule } from 'util/validate';
import { ValidationMessageBase } from 'module/validationMessage/main';

const ValidationDecorator = (superclass) => class T extends superclass {
    subs() {
        if (this.dom.validationMessageOutlet) {
            this.validationMessage = new ValidationMessageBase(this.dom.validationMessageOutlet);
        }
    }

    bindValidationMessage(validationMessage) {
        this.validationMessage = validationMessage;
    }

    validate(rules = this.internalValidationRules ?? []) {
        if (!rules.length) return [];

        const errors = [];
        const sideEffectCalls = [];
        rules.every((rule) => {
            // eslint-disable-next-line one-var-declaration-per-line, one-var
            let name, test, message, sideEffects, specificName, ruleValue;

            if (typeof rule === 'string') name = rule;
            else if (typeof rule === 'object') ({ name, test, message, sideEffects } = rule);
            // eslint-disable-next-line prefer-const
            ({ specificName, name, value: ruleValue } = parseRule(name));
            const validatableValue = this.getValue();
            const valid = test ? test(validatableValue) : checkRule({ name, value: ruleValue }, validatableValue);

            // Check for message pool
            if (!message && this.props.registeredValidationMessages) {
                message = this.props.registeredValidationMessages?.[specificName];
            }

            message = typeof message === 'function' ? message(ruleValue) : message;

            if (sideEffects) {
                sideEffectCalls.push(() => sideEffects({ name, valid, value: validatableValue }));
            }

            if (!valid) {
                errors.push({ [specificName]: ruleValue, message, specificName });
                if (this.props.sequentialValidation) return false;
            }
            return true;
        });

        if (errors.length) this.setInvalid(errors);
        else this.setValid();

        // Call side effects after re-rendering components (from setValid / setInvalid)
        sideEffectCalls.forEach((sideEffectFunctionCall) => sideEffectFunctionCall());

        this.updateProps({ errors });
        return errors;
    }

    validationSetup(config) {
        if (config.validationMessageOutlet) {
            this.updateProps({ explicitValidationMessageOutlet: true }, true);
            this.bindValidationMessage(config.validationMessageOutlet);
        } else {
            this.bindValidationMessage(this.validationMessage);
        }

        if (config.sequential) {
            this.updateProps({ sequentialValidation: true });
        }

        if (config.registeredValidationMessages) {
            this.updateProps({ registeredValidationMessages: config.registeredValidationMessages });
        }

        // Don't use props to store config because props can't store functions
        if (Array.isArray(config)) {
            this.internalValidationRules = config;
        } else {
            this.internalValidationRules = config.rules;
        }
        return true;
    }

    setInvalid(errors = []) {
        if (this.validationMessage) this.validationMessage.updateProps({ errors }, true);
        this.updateProps(Object.assign(this.getPropsFromDom(), { errors, error: true }), true);
    }

    setValid() {
        this.updateProps(Object.assign(this.getPropsFromDom(), { error: false }), true);
        if (this.validationMessage) {
            this.validationMessage.updateProps(Object.assign(this.validationMessage.props, { errors: [] }), true);
            this.validationMessage.render();
        }
    }

    /** @abstract * */
    getValue() {}
};

// Flexible multi-class inheritance via pseudo-decorators.
// Functionally equivalent to 'export class ValidatableInput extends AppModule {...}'
export const ValidatableInput = ValidationDecorator(AppModule);
