import { AppModule } from 'app/module';
import { userWatchlist } from 'app/userWatchlist';
import { trackGa4 } from 'util/googleAnalytics';
import 'module/icon/main';
import 'module/button/main';

import './styles.scss';
import Template from './template.hbs';

export class WatchlistButton extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        return {
            label: '.m-watchlistButton__label',
            button: '.m-watchlistButton__button',
        };
    }

    // 1. TODO: Why is "id" needed?
    // In the dom it translates to the string "id" since there is no corresponding value from the backend
    // Somehow before adding items to the watchlist it transforms into the correct id (I have no clue how or where)
    // Ideally you can just delete the whole "id" prop since "jobId" should be enough
    getPropsFromDom() {
        return {
            label: this.dom.label ? this.dom.label.getAttribute('data-label') : '',
            labelWatched: this.dom.label ? this.dom.label.getAttribute('data-labelWatched') : '',
            labelDesktopOnly: Boolean(this.dom.label?.classList.contains('m-watchlistButton__label--desktopOnly')),
            buttonStyle: this.dom.button.classList.contains('m-watchlistButton__button--buttonStyle'),
            jobId: this.dom.button.getAttribute('data-jobId'),
            smallButton: this.dom.button.classList.contains('m-watchlistButton__button--small'),
            smallPadding: this.dom.button.classList.contains('m-watchlistButton__button--smallPadding'),
            disabled: this.dom.button.disabled,
            isFetched: this.dom.button.getAttribute('data-fetched'),
            iconOnly: this.dom.button.classList.contains('m-watchlistButton__button--iconOnly'),
            id: this.dom.button.getAttribute('data-id'), // 1.
        };
    }

    ready() {
        if (!this.props.isFetched) {
            userWatchlist.fetchAll();
        }

        userWatchlist.events.on('onResponse', () => this.update());
        userWatchlist.events.on('onAddJob', () => this.update());
        userWatchlist.events.on('onRemoveJob', () => this.update());
    }

    domEvents() {
        this.dom.el.addEventListener('click', (e) => this.eventClicked(e));
    }

    eventClicked(e) {
        e.stopPropagation();

        const eventCategory = document.documentElement.getAttribute('data-event-category');
        const routeName = document.documentElement.getAttribute('data-route-name');
        let prefix = '';

        if (eventCategory === 'page: index') {
            prefix = 'HOME';
        } else if (eventCategory === 'page: job') {
            prefix = 'JOBD';
        } else if (eventCategory === 'page: jobs') {
            prefix = 'JOBS';
        } else if (eventCategory === 'page: jobalarm') {
            prefix = 'JA';
        } else if (eventCategory === 'page: jobmatching') {
            prefix = 'JM';
        } else if (eventCategory === 'page: watchlist') {
            prefix = 'WL';
        } else if (eventCategory === 'page: company-follow') {
            prefix = 'CA';
        } else if ((eventCategory === 'page: businesspage_ep')
            || (eventCategory === 'page: businesspage_epp')
            || (eventCategory === 'page: businesspage_bs')) {
            if (routeName === 'brandingSolution.index') {
                prefix = 'COMP';
            } else if (routeName === 'brandingSolution.jobs') {
                prefix = 'COMP_JA';
            }
        }

        if (userWatchlist.isWatched(this.props.id)) {
            userWatchlist.removeJob(this.props.id);

            trackGa4({
                event: 'click_element',
                element: `${prefix}: Watchlist Remove`,
                element_detail: this.props.jobId || null,
            });
        } else if (!this.props.disabled) {
            userWatchlist.addJob(this.props.id, this.dom.el.parentElement);

            trackGa4({
                event: 'click_element',
                element: `${prefix}: Watchlist Add`,
                element_detail: this.props.jobId || null,
            });
        }
    }

    // TODO: it says unused, pls check if this can be deleted safely
    addJob(id) {
        userWatchlist.addJob(id);
    }

    update() {
        const isWatched = userWatchlist.isWatched(this.props.id);
        const disabled = isWatched && this.props.disabled ? false : this.props.disabled;

        this.updateProps({
            isWatched,
            disabled,
        }, true);
    }
}
