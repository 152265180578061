import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { user } from 'app/user';

if (SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: APP_ENVIRONMENT,
        release: APP_RELEASE,
        allowUrls: [
            /.*\.karriere\.at/,
            /kcdn\.at/,
        ],
        integrations: [
            new BrowserTracing({
                tracingOrigins: [
                    'localhost',
                    'k4.test',
                    'kcdn.at',
                    /^\//,
                    // Match all karriere.at origins except `gtm.karriere.at`.
                    // If you want to exclude other subdomains you can do it
                    // with a regular expression like this:
                    // `/^((?!gtm|other\.).)*karriere.at.*$/`
                    /^((?!gtm\.).)*karriere.at.*$/,
                ],
            }),
        ],
        tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
        ignoreErrors: ['ResizeObserver loop limit exceeded'],
    });

    Sentry.setTag('source', 'js');
    const userUuid = user.getUserUuid();
    if (userUuid) Sentry.setUser({ id: userUuid });
}
