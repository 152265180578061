import { ActionIconLabel } from 'module/actionIconLabel/main';
import { IconLabel } from 'module/iconLabel/main';
import 'module/icon/main';
import './styles.scss';
import { ValidatableInput } from 'app/validatableInput';
import { generateAutocompleteInstructionText } from 'util/a11y';
import Template from './template.hbs';

const minSelectionValue = 0;
const maxSelectionValue = 99999;

export class Input extends ValidatableInput {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        this.dom.input = this.dom.el.querySelector('.m-input__input');
        this.dom.label = this.dom.el.querySelector('.m-input__label');
        this.dom.link = this.dom.el.querySelector('.m-input__link');
        this.dom.info = this.dom.el.querySelector('.m-input__info');
        this.dom.infoTrigger = this.dom.el.querySelector('.m-input__infoTrigger');
        this.dom.infoAction = this.dom.el.querySelector('.m-input__infoAction');
        this.dom.iconLabelContainer = this.dom.el.querySelector('.m-input__iconLabel');
        this.dom.iconLabel = this.dom.el.querySelector('.m-input__iconLabel .m-iconLabel');
        this.dom.actionIcon = this.dom.el.querySelector('.m-input__actionIcon');
        this.dom.actionIconLabel = this.dom.el.querySelector('.m-input__actionIcon .m-actionIconLabel');
        this.dom.validationMessageOutlet = this.dom.el.querySelector('.m-input__validationMessageOutlet');
    }

    domEvents() {
        if (this.dom.link) {
            this.dom.link.addEventListener('click', (e) => {
                if (e) e.preventDefault();
                this.events.emit('openLink');
                if (window.self !== window.parent) {
                    window.location.href = this.dom.link.href;
                } else {
                    window.open(this.props.link.url, this.props.link.target);
                }
            });
        }

        if (this.dom.actionIcon) {
            this.dom.actionIcon.addEventListener('click', () => this.events.emit('actionIconClicked', this.props.id));
            this.dom.input.addEventListener('keyup', () => this.inputChanged());
        }

        this.dom.input.addEventListener('click', (e) => this.events.emit('click', e));
        this.dom.input.addEventListener('blur', (e) => this.events.emit('blur', e));
        this.dom.input.addEventListener('focus', (e) => this.events.emit('focus', e));
        this.dom.input.addEventListener('keyup', (e) => this.events.emit('change', e));
        this.dom.input.addEventListener('select', (e) => this.events.emit('select', e));

        // In the case of re-rendering this module, the event listener
        // set in `ready()` is not bound again, therefore we have to bind
        // the event listener a second time here.
        // The check for props does prevent double binding of the event.
        if (this.props.hasAutocomplete) {
            this.dom.input.addEventListener('keyup', (e) => {
                this.events.emit('keyup', e.key);
            });
        }
    }

    inputChanged() {
        if (this.getValue() !== '') {
            this.addActionIcon();
        } else {
            this.removeActionIcon();
        }
    }

    ready() {
        // We add the event listener here instead of `domEvents()`
        // because properties need to be initialized.
        if (this.props.hasAutocomplete) {
            generateAutocompleteInstructionText();
            this.dom.input.addEventListener('keyup', (e) => this.events.emit('keyup', e.key));
        }

        if (this.getValue() !== '' && this.dom.actionIcon) {
            this.addActionIcon();
        }
    }

    getPropsFromDom() {
        let iconLabelProps = [];
        let actionIconLabelProps = [];

        if (this.actionIconLabel) actionIconLabelProps = this.actionIconLabel.getPropsFromDom();
        if (this.iconLabel) iconLabelProps = this.iconLabel.getPropsFromDom();

        return { placeholder: this.dom.input.placeholder,
            value: this.dom.input.value,
            size: this.dom.input.size,
            type: this.dom.input.type,
            id: this.dom.input.name,
            disabled: this.dom.input.disabled,
            readonly: !!this.dom.input.getAttribute('readonly'),
            error: this.dom.el.classList.contains('m-input--error'),
            // save in props for salaryBenchmarkForm BE validation
            errors: this.validationMessage?.getPropsFromDom().errors,
            name: this.dom.input.name,
            min: this.dom.input.min,
            max: this.dom.input.max,
            step: this.dom.input.step,
            maxlength: this.dom.input.maxLength,
            autofocus: this.dom.input.autofocus,
            inputMode: this.dom.input.hasAttribute('inputmode') ? this.dom.input.getAttribute('inputmode') : false,
            label: !this.dom.label ? false : this.dom.label.innerHTML,
            hideLabel: this.dom.label?.classList.contains('m-input__label--screenreaderOnly') ?? false,
            excludeLinkTab: !!this.dom.link && this.dom.link.getAttribute('tabindex'), // TODO check if used
            jobid: this.dom.input.getAttribute('data-jobid'),
            hasAutocomplete: this.dom.input.autocomplete === 'off',
            iconLabel: this.dom.iconLabelContainer,
            hasActionIcon: !!this.dom.actionIcon,
            hasSelect: this.dom.input.classList.contains('m-input__input--hasSelect'),
            ...actionIconLabelProps,
            ...iconLabelProps };
    }

    subs() {
        super.subs();
        if (this.dom.actionIconLabel) {
            this.actionIconLabel = new ActionIconLabel(this.dom.actionIconLabel);
        }
        if (this.dom.iconLabel) {
            this.iconLabel = new IconLabel(this.dom.iconLabel);
        }
    }

    getValue() {
        return this.dom.input.value;
    }

    setValue(value) {
        this.dom.input.value = value;
        this.inputChanged();
    }

    setFocus() {
        this.dom.input.focus();
    }

    select() {
        this.dom.input.setSelectionRange(minSelectionValue, maxSelectionValue);
    }

    resetValue() {
        this.setValue('');
        this.setFocus();
        this.removeActionIcon();
    }

    removeActionIcon() {
        if (this.dom.actionIcon && this.dom.actionIcon.classList.contains('m-input__actionIcon--visible')) {
            this.dom.actionIcon.classList.remove('m-input__actionIcon--visible');
        }
    }

    addActionIcon() {
        if (this.dom.actionIcon && !this.dom.actionIcon.classList.contains('m-input__actionIcon--visible')) {
            this.dom.actionIcon.classList.add('m-input__actionIcon--visible');
        }
    }

    setAutocompleteExpanded(isExpanded) {
        // Set aria attributes directly to avoid nasty re-rendering issues
        this.dom.el.setAttribute('aria-expanded', isExpanded);

        if (!isExpanded) {
            this.hasActiveDescendant(false);
        }
    }

    hasActiveDescendant(hasActiveDescendant = true) {
        // Set aria attributes directly to avoid nasty re-rendering issues
        if (hasActiveDescendant) {
            this.dom.input.setAttribute('aria-activedescendant', 'selectedAutocompleteOption');
        } else {
            this.dom.input.setAttribute('aria-activedescendant', '');
        }
    }
}
