function throttle(func, wait) {
    let timeout;

    return function startTimeout(...args) {
        const context = this;
        if (!timeout) {
            timeout = setTimeout(() => {
                func.apply(context, args);
                timeout = null;
            }, wait);
        }
    };
}

export { throttle };
