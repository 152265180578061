import { app } from 'app/app';

/**
 * Recursive function to find which module a certain dom element belongs to.
 * @param {HTMLElement} domElement
 * @param current - Current component being searched for the domElement
 * @returns AppModule
 */
export const findModuleByDomElement = (domElement, current = app) => {
    let component = current;
    Object.keys(current).forEach((objKey) => {
        const sub = current[objKey];
        if (typeof current[objKey] === 'object'
            && Object.prototype.hasOwnProperty.call(current[objKey], 'dom')
            && sub.dom.el.contains(domElement)) {
            component = findModuleByDomElement(domElement, sub);
        }
    });
    return component;
};
