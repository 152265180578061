export function enableExternalScripts() {
    const $inlineScripts = document.querySelectorAll('script[type="text/plain"]');
    for (let i = 0; i < $inlineScripts.length; i += 1) {
        const $old = $inlineScripts[i];

        const $new = document.createElement('script');
        $new.innerHTML = $inlineScripts[i].innerHTML;

        $old.parentNode.replaceChild($new, $old);
    }
}
