var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"m-headerSmall\">\n    <div class=\"k-c-brandline\"></div>\n    <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"linkBack") : depth0), depth0))
    + "\" class=\"m-headerSmall__back\" aria-label=\"Zurück\">\n"
    + ((stack1 = container.invokePartial(require("../icon/files/dls/arrow-left--s.hbs"),depth0,{"name":"module/icon/files/dls/arrow-left--s","hash":{"size":"small"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </a>\n    <div class=\"m-headerSmall__title\" aria-hidden=\"true\">\n        "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});