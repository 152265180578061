/**
 * @module util/detect
 */

const rBreakpoints = /^(?:mobile|tablet|desktop)/i;

/**
 * @returns {string|null}
 */
function breakpoint() {
    const value = window.getComputedStyle(document.documentElement).getPropertyValue('font-family');

    if (rBreakpoints.test(value)) {
        return value;
    }
    return null;
}

function touch() {
    return 'ontouchstart' in document.documentElement;
}

/**
 * Check if the History API is available.
 *
 * @returns {boolean}
 */
function historyApi() {
    return !!(window.history && window.history.pushState);
}

/**
 * Check if the Web Share API is available.
 *
 * @returns {boolean}
 */
function shareApi() {
    return navigator.share !== undefined;
}

export { breakpoint, historyApi, touch, shareApi };
