import { AppModule } from 'app/module';
import Template from './template.hbs';
import './styles.scss';

export class JobFieldsListingItem extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        return {
            label: '.m-jobFieldsListingItem__label',
            badge: '.m-jobFieldsListingItem__badge',
        };
    }

    getPropsFromDom() {
        return {
            link: this.dom.el ? this.dom.el.href : '/',
            label: this.dom.label ? this.dom.label.innerText : '',
            count: this.dom.badge ? this.dom.badge.innerText : '',
        };
    }
}
