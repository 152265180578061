import { AppModule } from 'app/module';
import { userNotifications } from 'app/userNotifications';
import { NotificationBar } from 'module/notificationBar/main';
import { HeaderSmall } from 'module/headerSmall/main';
import { Toast } from 'module/toast/main';
import { Footer } from 'module/footer/main';
import { LightBox } from 'module/lightBox/main';
import {
    sendServerSideGa4Events,
} from 'util/googleAnalytics';
import { LoginModal } from 'module/loginModal/main';
import { Snackbar } from 'module/snackbar/main';
import { SmallFooter } from 'module/smallFooter/main';
import {
    ENDPOINT_USER_LOGIN,
    ENDPOINT_USER_REGISTER,
} from 'app/endpoints';
import { REGEX_JOBSEARCH_CONTROLLER } from 'app/regularExpressions';
import { TIME_SNACKBAR_SHORT } from 'app/timings';
import client from '@karriereat/mf-header-client';
import './styles.scss';

let headerClientReady = false;
let snackbarTimeout = false;

export class MasterLayout extends AppModule {
    domBindings() {
        return {
            html: 'html',
            body: 'body',
            master: '.l-master',
            notificationBar: '.m-notificationBar',
            toast: '.m-toast',
            footer: '.m-footer',
            smallFooter: '.l-master__footer .m-smallFooter',
            lightBox: '.m-lightBox',
            content: '.l-master__content',
            loginModal: '.m-loginModal',
            snackbar: '.l-master__snackbar .m-snackbar',
            headerContainer: '.l-master__headerContainer',
            headerSmallContainer: '.l-master__headerSmallContainer',
        };
    }

    domEvents() {
        window.addEventListener('mf-header:click-loginButton', () => this.onLoginButtonClick());
        window.addEventListener('mf-header:click-registerLink', () => this.onRegisterLinkClick());
        window.addEventListener('mf-header:click-nav-item', (e) => this.onNavItemClick(e));
    }

    subs() {
        this.notificationBar = new NotificationBar(this.dom.notificationBar);
        this.toast = new Toast(this.dom.toast);
        if (this.dom.footer) {
            this.footer = new Footer(this.dom.footer);
        }
        this.lightBox = new LightBox(this.dom.lightBox);
        if (this.dom.loginModal) {
            this.loginModal = new LoginModal(this.dom.loginModal);
        }
        if (this.dom.snackbar) {
            this.snackbar = new Snackbar(this.dom.snackbar);
        }
        if (this.dom.headerSmallContainer) {
            this.headerSmallContainer = new HeaderSmall(this.dom.headerSmallContainer);
        }
        if (this.dom.smallFooter) {
            this.smallFooter = new SmallFooter(this.dom.smallFooter);
        }
    }

    subEvents() {
        this.lightBox.events.on('showContent', () => this.showContent());
        this.lightBox.events.on('hideContent', () => this.hideContent());
        this.lightBox.events.on('scrollToSection', () => {
            this.events.emit('scrollToSection');
        });
        this.lightBox.events.on('pushState', () => {
            this.events.emit('pushState');
        });
        if (this.loginModal) {
            this.loginModal.events.on('removeModalStyle', () => {
                this.removeModalStyle();
            });
            this.loginModal.events.on('showSnackbar', (e) => {
                this.showSnackbar(
                    TIME_SNACKBAR_SHORT,
                    false,
                    false,
                    false,
                    false,
                    e.arguments[0],
                );
            });
        }
        if (this.snackbar) {
            this.snackbar.events.on('undo', (e) => {
                this.events.emit('undo', e.arguments[0], e.arguments[1], e.arguments[2]);
            });
        }
        userNotifications.events.on('update', () => this.updateUserNotifications());
    }

    hideContent() {
        this.props.scrollPosition = window.pageYOffset;
        this.dom.headerContainer?.classList.add('l-master__headerContainer--hide');
        this.dom.headerSmallContainer?.classList.add('l-master__headerSmallContainer--hide');
        this.dom.content.classList.add('l-master__content--hide');
        this.dom.footer.classList.add('m-footer--hide');
    }

    showContent() {
        this.dom.headerContainer?.classList.remove('l-master__headerContainer--hide');
        this.dom.headerSmallContainer?.classList.remove('l-master__headerSmallContainer--hide');
        this.dom.content.classList.remove('l-master__content--hide');
        this.dom.footer.classList.remove('m-footer--hide');
        if (this.props.scrollPosition) {
            window.scrollTo(0, this.props.scrollPosition);
        }
    }

    hideFooter() {
        if (this.dom.smallFooter && !this.dom.smallFooter.classList.contains('m-smallFooter--hide')) {
            this.dom.smallFooter.classList.add('m-smallFooter--hide');
        }
    }

    showFooter() {
        if (this.dom.smallFooter && this.dom.smallFooter.classList.contains('m-smallFooter--hide')) {
            this.dom.smallFooter.classList.remove('m-smallFooter--hide');
        }
    }

    ready() {
        client.onReady(this.headerClientReady);
        sendServerSideGa4Events();
    }

    headerClientReady() {
        headerClientReady = true;
        client.updateNotifications(userNotifications.notifications);
    }

    updateUserNotifications() {
        if (headerClientReady) {
            client.updateNotifications(userNotifications.notifications);
        }
    }

    onLoginButtonClick() {
        this.openLoginModal(`${ENDPOINT_USER_LOGIN}?redirectUrl=${encodeURIComponent(window.location.href)}`, true);
    }

    onNavItemClick(e) {
        this.openLoginModal(`${ENDPOINT_USER_LOGIN}?redirectUrl=${e.detail.redirectUrl}`, true, true);
    }

    onRegisterLinkClick() {
        this.openLoginModal(`${ENDPOINT_USER_REGISTER}`, false, false, true);
    }

    addModalStyle(captcha = false) {
        const scrollPosition = window.pageYOffset;

        if (this.dom.html.style.height !== '100vh') {
            this.dom.html.style.height = '100vh';
        }

        if (this.dom.body.style.overflow !== 'hidden') {
            this.dom.body.style.height = '100%';
            this.dom.body.style.width = '100%';
            this.dom.body.style.overflow = 'hidden';
            this.dom.body.style.position = 'fixed';

            if (captcha) {
                this.dom.body.setAttribute('data-position', `-${scrollPosition}px`);
            } else {
                this.dom.body.style.top = `-${scrollPosition}px`;
            }
        }
    }

    removeModalStyle(captcha = false) {
        let scrollPosition = 0;

        if (captcha) {
            scrollPosition = this.dom.body.getAttribute('data-position');
        } else {
            scrollPosition = this.dom.body.style.top;
        }

        scrollPosition = scrollPosition !== 0 ? parseInt(scrollPosition.substr(1, scrollPosition.length), 10) : 0;

        if (this.dom.html.style.height === '100vh') {
            this.dom.html.style.height = '';
        }

        if (this.dom.body.style.overflow !== 'visible') {
            this.dom.body.style.height = 'auto';
            this.dom.body.style.width = 'auto';
            this.dom.body.style.overflow = 'visible';
            this.dom.body.style.position = 'static';
            if (scrollPosition !== 0) {
                window.scrollTo(0, scrollPosition);
            }
        }
    }

    openLoginModal(
        src = false,
        hasParams = false,
        commingFromJobSearchPage = false,
        register = false,
    ) {
        const isJobSearch = new RegExp(REGEX_JOBSEARCH_CONTROLLER).test(window.location.pathname);

        if (isJobSearch && !commingFromJobSearchPage && src) {
            this.events.emit('getUserJobSearchRequest', register);
        } else if (this.loginModal) {
            this.loginModal.toggle(src, hasParams);
        }
    }

    showSnackbar(
        animationDuration = TIME_SNACKBAR_SHORT,
        id = false,
        dislikeId = false,
        companyName = false,
        companyId = false,
        text = 'Gespeichert!',
        icon = true,
        link = false,
        linkEvent = false,
        linkText = undefined,
        actionLink = false,
    ) {
        if (this.snackbar?.props.displayed) {
            this.clearSnackBar();
        }

        this.snackbar?.updateProps({
            displayed: true,
            text,
            link,
            dislikeId,
            value: id,
            company: companyName !== false,
            companyId,
            animationDuration,
            icon,
            linkText,
            linkEvent,
            actionLink,
        }, true);

        snackbarTimeout = setTimeout(() => {
            this.snackbar?.updateProps({ displayed: false }, true);
        }, this.snackbar?.props.animationDuration);
    }

    clearSnackBar() {
        clearTimeout(snackbarTimeout);
        this.snackbar?.updateProps({ displayed: false }, true);
    }
}
