export const MAIL_NEVER = 0;
export const MAIL_DAILY = 1;
export const MAIL_WEEKLY = 2;

export const MAIL_NEVER_LABEL = 'Nie';
export const MAIL_DAILY_LABEL = 'Täglich';
export const MAIL_WEEKLY_LABEL = 'Wöchentlich';

export function getMailNotificationIntervalLabel(notificationInterval) {
    switch (notificationInterval) {
    case MAIL_DAILY:
        return MAIL_DAILY_LABEL;
    case MAIL_WEEKLY:
        return MAIL_WEEKLY_LABEL;
    default:
        return MAIL_NEVER_LABEL;
    }
}
