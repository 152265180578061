import GeneralHistoryClass from 'util/client-side-storage/classes/generalHistoryClass';

const maxHistoryEntries = 6;
const storeName = 'recently-visited';
const key = 'jobs';

export class VisitedJobsHistoryClass extends GeneralHistoryClass {
    constructor(config) {
        config.storeName = storeName;
        config.maxHistoryEntries = maxHistoryEntries;
        super(config);
        this.key = key;
    }

    async getJobs(callback) {
        return await super.getItem(this.key, callback) ?? [];
    }

    async getJobIds(callback) {
        return (await this.getJobs(callback))
            .reduce((acc, cur) => { acc.push(cur.jobId); return acc; }, []);
    }

    async addJob(jobId, callback) {
        if (!this.historyEnabled()) return false;
        if (!jobId) return false;

        if (await this.jobExistsInHistory(jobId)) {
            await this.removeJobFromHistory(jobId);
        }

        let visitedJobs = await this.getJobs();
        visitedJobs.unshift({ jobId, lastVisited: new Date() });
        visitedJobs = visitedJobs.slice(0, this.maxHistoryEntries);

        return this.setItem(this.key, visitedJobs, callback);
    }

    async jobExistsInHistory(jobId) {
        return (await this.getJobs()).find((job) => job.jobId === jobId);
    }

    async removeJobFromHistory(jobId) {
        const jobs = await this.getJobs();
        const index = jobs.findIndex((job) => job.jobId === jobId);
        if (index > -1) {
            jobs.splice(index, 1);
            return this.setItem(this.key, jobs);
        }
        return false;
    }
}
