var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"m-lightBox__inner\">\n            <div class=\"m-lightBox__closeBar\">\n"
    + ((stack1 = container.invokePartial(require("../icon/files/dls/cross--s.hbs"),depth0,{"name":"module/icon/files/dls/cross--s","hash":{"class":"m-lightBox__closeTrigger","size":"small"},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"m-lightBox__content\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"mediaItems") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":43,"column":25}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPagination") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":16},"end":{"line":55,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"m-lightBox__item "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"current") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":49},"end":{"line":9,"column":96}}})) != null ? stack1 : "")
    + "\">\n                        <div class=\"m-lightBox__imageContainer\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isImage") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":11,"column":28},"end":{"line":30,"column":39}}})) != null ? stack1 : "")
    + "\n                            <div class=\"m-lightBox__meta m-lightBox__meta--hidden\">\n                                <span class=\"m-lightBox__title\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"subtitle") : depth0), depth0))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"hasPagination") : depths[1]),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":32},"end":{"line":37,"column":39}}})) != null ? stack1 : "")
    + "                            </div>\n                        </div>\n                        <div class=\"m-lightBox__placeholder\">\n                        </div>\n                    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "m-lightBox__item--current";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"m-lightBox__video m-lightbox__mediaItem\">\n"
    + ((stack1 = container.invokePartial(require("../video/template.hbs"),depth0,{"name":"module/video/template","hash":{"active":true,"insideLightBox":true,"videoId":(depth0 != null ? lookupProperty(depth0,"mobileSrc") : depth0)},"data":data,"indent":"                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"tabletSrc") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":16,"column":32},"end":{"line":29,"column":39}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <picture>\n                                        <source class=\"m-lightBox__mobileSrc\" media=\"(max-width:719px)\">\n                                        <source class=\"m-lightBox__tabletSrc\" media=\"(min-width:720px)\">\n                                        <img class=\"m-lightBox__image m-lightbox__mediaItem\"\n                                             data-mobilesrc=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"mobileSrc") : depth0), depth0))
    + "\" data-tabletsrc=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tabletSrc") : depth0), depth0))
    + "\"\n                                             alt=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"subtitle") : depth0), depth0))
    + "\">\n                                    </picture>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <picture>\n                                        <img class=\"m-lightBox__image m-lightbox__mediaItem\"\n                                             data-mobilesrc=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"mobileSrc") : depth0), depth0))
    + "\" alt=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"subtitle") : depth0), depth0))
    + "\">\n                                    </picture>\n";
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <span class=\"m-lightBox__imageNumber\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"page") : depth0), depth0))
    + "\n                                        von "
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"mediaItems") : depths[1])) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "</span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <div class=\"m-lightBox__paginationBar m-lightBox__paginationBar--previous\">\n                        <a class=\"m-lightBox__button m-lightBox__button--previous\">\n"
    + ((stack1 = container.invokePartial(require("../icon/files/dls/arrow-left--s.hbs"),depth0,{"name":"module/icon/files/dls/arrow-left--s","hash":{"class":"m-lightBox__icon--previous","size":"xl"},"data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </a>\n                    </div>\n                    <div class=\"m-lightBox__paginationBar m-lightBox__paginationBar--next\">\n                        <a class=\"m-lightBox__button m-lightBox__button--next\">\n"
    + ((stack1 = container.invokePartial(require("../icon/files/dls/arrow-right--s.hbs"),depth0,{"name":"module/icon/files/dls/arrow-right--s","hash":{"size":"xl"},"data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </a>\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"m-lightBox\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":58,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});