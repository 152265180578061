import Dispatcher from 'dispatcherjs';
import { AUTH_COOKIE } from 'app/cookies';
import { getCookie } from 'util/cookie';

class User {
    constructor() {
        this.events = new Dispatcher();

        if (this.wasLoggedOut()) {
            this.clearKarlWebStorage();
        }
    }

    /**
     * Returns `true` if the user is logged-in
     *
     * By default this function uses the `data-loggedin`-attribute from the DOM to determine
     * the users authentication-status. So it will only tell you if the user was logged-in at
     * the time the page was loaded.
     * If you want to know if the user is still logged in, set the `now`-param to `true`.
     * This is especially useful if you want to know if the user logged-out in another tab.
     *
     * @param {Boolean} now - Activate this param to check if the user is logged-in right now
     * @returns {Boolean|null}
     */
    isLoggedIn(now = false) {
        return now ? Boolean(getCookie(AUTH_COOKIE)) : document.body.getAttribute('data-loggedin');
    }

    wasLoggedOut() {
        return document.body.getAttribute('data-wasloggedout');
    }

    hasProfile() {
        return document.body.getAttribute('data-hasprofile');
    }

    getUserUuid() {
        return document.body.getAttribute('data-useruuid');
    }

    clearKarlWebStorage() {
        sessionStorage.removeItem('karL-vuex');
    }
}

export const user = new User();
