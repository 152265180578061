import { throttle } from 'util/throttle';
import { isInViewport } from 'util/isInViewport';

export class ViewportObserver {
    constructor() {
        this.modules = [];

        const changeThrottled = throttle(() => this.onViewportChange(), 500);

        window.addEventListener('scroll', () => changeThrottled());
        window.addEventListener('resize', () => changeThrottled());
    }

    onViewportChange() {
        this.modules.forEach((module, index) => {
            if (isInViewport(module.dom.el)) {
                module.entersViewport();
                delete this.modules[index];
            }
        });
    }

    add(module) {
        if (isInViewport(module.dom.el)) {
            module.entersViewport();
        } else {
            this.modules.push(module);
        }
    }
}

export const viewportObserver = new ViewportObserver();
