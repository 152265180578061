import { AppModule } from 'app/module';
import 'module/icon/main';
import Template from './template.hbs';
import './styles.scss';

export class ActionIconLabel extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    getPropsFromDom() {
        return {
            actionIconLabelPlus: !!this.dom.el.querySelector('.m-icon__plus'),
            actionIconLabelCross: !!this.dom.el.querySelector('.m-icon__cross'),
            hasLinkStyle: this.dom.el.classList.contains('k-c-link'),
        };
    }
}
