import { AppModule } from 'app/module';
import { trackGa4 } from 'util/googleAnalytics';
import Template from './template.hbs';
import './styles.scss';

export class ProposalItemPill extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    getPropsFromDom() {
        const eventName = this.dom.el.getAttribute('data-event-name');

        return {
            eventName,
        };
    }

    domEvents() {
        this.dom.el.addEventListener('click', () => this.proposalLinkClicked());
    }

    proposalLinkClicked() {
        let elementDetail = null;

        if (this.props.eventName === 'keyword') {
            elementDetail = 'Keywords';
        } else if (this.props.eventName === 'location') {
            elementDetail = 'Locations';
        } else if (this.props.eventName === 'random') {
            elementDetail = 'Random';
        }

        trackGa4({
            event: 'click_element',
            element: 'HOME: Proposals',
            element_detail: elementDetail,
        });
    }
}
