import { KEYCODE_TABULATOR } from 'app/keyCodes';

/**
 * Generates a hidden (screen-reader-only) dom node inputs reference
 * to explain how the autocomplete functionality works, if it doesn't exist already.
 * Used in input components which have autocompletes
 */
export function generateAutocompleteInstructionText() {
    if (document.getElementById('autocompleteExplainer')) return;

    const el = document.createElement('div');
    el.innerText = `Wenn Resultate für die Autovervollständigung verfügbar sind, benutze die
                    Pfeil hoch und Pfeil runter Tasten zum Auswählen und Enter zum Bestätigen.
                    Smartphone Nutzer können Resultate per Berührung auswählen.`;
    el.style.display = 'none';
    el.id = 'autocompleteExplainer';
    document.body.appendChild(el);
}

/**
 * A11Y helper function that returns all focusable elements
 * inside a html specific element (default value = document)
 *
 * @param {HTMLElement} root
 */
export function getKeyboardFocusableElements(root = document) {
    if (!root || !root.querySelectorAll) return [];
    return [
        ...root.querySelectorAll(
            'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])',
        ),
    ].filter((el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'));
}

/**
 * Checks if there is any element with the `k-screen-reader-only` class in the DOM.
 * If not, generate screen reader only styles.
 */

function createScreenReaderClass() {
    if (!document.getElementsByClassName('k-screen-reader-only').length) {
        const css = '.k-screen-reader-only {'
            + '    position: absolute;'
            + '    overflow: hidden;'
            + '    clip: rect(0, 0, 0, 0);'
            + '    clip-path: inset(50%);'
            + '    margin: -1px;'
            + '    padding: 0;'
            + '    width: 1px;'
            + '    height: 1px;'
            + '    border: 0;'
            + '}';
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');

        head.appendChild(style);
        style.appendChild(document.createTextNode(css));
    }
}

/**
 * Lets a screen reader read out a string.
 */
export function readScreenReaderText(textToRead, distinct = false) {
    let el = document.getElementById('screenReaderReadAloud');
    if (!el) {
        el = document.createElement('div');
        el.id = 'screenReaderReadAloud';
        el.classList.add('k-screen-reader-only');
        el.setAttribute('aria-atomic', 'true');
        createScreenReaderClass();
        document.body.appendChild(el);
    }

    el.setAttribute('aria-live', 'assertive');
    if (!distinct) el.innerText = '';
    setTimeout(() => { el.innerText = textToRead; }, 10);
}

/**
 * A11y helper function - the next tab leads to the supplied element (or the element after if its second parameter is
 * set to true). This is useful for directing focus without directly focusing an element,
 * for example if you don't want to prompt a screenreader response.
 * @param {HTMLElement} element
 * @param {boolean} after - If set to true, the next tab will lead to the element AFTER the specified one
 * @param {boolean} deferUntilTab - If set to true, focus will not be set until the next tab key press,
 * preventing any possible scrolling that might occur otherwise
 */
export function directFocusTo(element, after = false, deferUntilTab = false) {
    if (!element || !element.parentNode) return;

    const directFocusToElement = () => {
        const tempEl = document.createElement('div');
        tempEl.tabIndex = 0;
        const { parentNode } = element;
        const previousElement = after ? element.nextSibling : element;
        parentNode.insertBefore(tempEl, previousElement);
        tempEl.focus();
        tempEl.remove();
    };

    const deferCallback = (e) => {
        if (e.key !== KEYCODE_TABULATOR) return;
        window.removeEventListener('keydown', deferCallback);

        directFocusToElement();
    };

    if (deferUntilTab) {
        window.addEventListener('keydown', deferCallback);
    } else {
        directFocusToElement();
    }
}
