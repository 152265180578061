import Dispatcher from 'dispatcherjs';
import deepEqual from 'util/deepEqual';
import deepClone from 'util/deepClone';
import { viewportObserver } from 'util/viewportObserver';
import { ModuleValidation } from 'app/module-validation';

/**
 * App Module
 */
export class AppModule {
    /**
     * Creates a new app module instance and runs boot sequence
     * @param {HTMLElement} $el
     * @param {Array} childs a list of child elements
     * The root dom element for the module scope
     * @return this
     */
    constructor($el, childs = []) {
        // Validation of $el
        if (!$el || $el.length === 0 || !($el instanceof window.HTMLElement)) {
            throw new Error(`${this.constructor.name} has invalid $el (single HTMLElement)`);
        }

        /**
         * This is where the module state is hosted.
         * Try to stay flat! Dont pass references. #immutable
         *
         * For the lazy ones: Read {@link getProps}
         * and write {@link setProps}
         * @type {Object}
         */
        this.props = {};

        /**
         * All the dom bindings for the module
         *
         * @type {Object}
         * @property {HTMLElement} dom.el
         */
        this.dom = {
            el: $el,
        };

        /**
         * All child elements of the module
         *
         * @type {Array}
         */
        this.childs = childs;

        /**
         * Module event emitter system - "mic check 1,2"
         * @type {Dispatcher}
         * @external {Dispatcher} https://github.com/ozantunca/DispatcherJS
         *
         * @example
         * this.events.emit("yo");
         * this.events.on("yo", () => doLeStuff());
         */
        this.events = new Dispatcher();

        this.validation = ModuleValidation(this);

        // kick it!
        this.boot();
    }

    /**
     * Boot sequence in a nutshell:
     *  1. dom handling (selector and events)
     *  2. get initial properties
     *  3. run submodules
     *  4. ready
     */
    boot() {
        this.resolveDomBindings(
            this.domBindings(),
        );
        this.domEvents();

        this.setTemplate();
        this.subs();

        this.updateProps(
            this.getPropsFromDom(),
        );

        this.subsProps();
        this.subEvents();
        this.validationRules();
        this.ready();
    }

    /**
     * Sets a new state for the module.
     * Does dirty checking against current state.
     *
     * @param {Object} props={}
     * A props object
     * @param {Boolean} [render=false]
     * If set to true module will render if dirty
     * @return {Boolean}
     * True if state update is dirty
     */
    setProps(props = {}, render = false) {
        if (deepEqual(this.props, props)) {
            // state did not change. I'm out
            return false;
        }

        // clone for no references
        this.props = deepClone(props);

        // props have changed hook
        this.onPropsChanged();

        // bubble new state down
        this.subsProps();

        // render shorthand
        if (render) this.render();

        // module has been rendered
        this.afterRender();

        return true;
    }

    /**
     * Merges new states into module. See {@link setProps}
     */
    updateProps(props, render = false) {
        return this.setProps(
            { ...this.props, ...props },
            render,
        );
    }

    /**
     * Getter for getting a copy of the current app state
     * @return {Object} - Copy of the appstate
     */
    getProps() {
        return deepClone(this.props);
    }

    /**
     * Getter for child modules
     * @return {Array}
     */
    getChilds() {
        return this.childs;
    }

    /**
     * Render module into its root dom element.
     * Requires a compiled hbs template in this.template
     *
     * @return {Boolean} - Did we successfully render?
     */
    render() {
        if (!this.template) return false; // throw "cannot render without template";

        // Create the markup with current state.
        const markup = this.template(
            { ...this.props, client: true },
        );

        // Create a new node and pass the markup
        const domTemplateWrap = document.createElement('div');
        domTemplateWrap.innerHTML = markup;

        // Replace the old node - Quasi outerHTML
        const oldNode = this.dom.el;

        // Try to retain focus
        let id;
        if (oldNode.contains(document.activeElement)) {
            id = document.activeElement.id;
        }

        const newNode = domTemplateWrap.firstChild;
        const { parentNode } = oldNode;
        if (!parentNode) return false;
        parentNode.replaceChild(newNode, oldNode);

        if (id) {
            document.getElementById(id)?.focus();
        }

        // reference new dom node in bindings
        this.dom.el = newNode;

        // rebirth - life starts again ...
        this.resolveDomBindings(
            this.domBindings(),
        );
        this.domEvents();

        // bubble down
        this.subs();
        this.subsProps();
        this.subEvents();
        this.validationRules();

        return true;
    }

    /**
     * When called the current module is added to the late load observer
     * will call the modules entersViewport method when this.dom.el enters viewport
     */
    addViewportObserver() {
        // @todo on first user interaction
        viewportObserver.add(this);
    }

    /**
     * Resolve bindings if domBindings returns an object
     */
    resolveDomBindings(domBindings) {
        if (domBindings instanceof Object) {
            const hasChildNodesAtAll = (this.dom.el.children.length > 0);

            Object.keys(domBindings).forEach((name) => {
                const selector = domBindings[name];
                const isArraySelector = selector instanceof Array;

                if (hasChildNodesAtAll && isArraySelector) {
                    this.dom[name] = this.dom.el.querySelectorAll(selector[0]);
                } else if (!hasChildNodesAtAll && isArraySelector) {
                    this.dom[name] = [];
                } else if (hasChildNodesAtAll && !isArraySelector) {
                    if (selector === 'html') {
                        this.dom[name] = window.document.querySelector('html');
                    } else {
                        this.dom[name] = this.dom.el.querySelector(selector);
                    }
                } else if (!hasChildNodesAtAll && !isArraySelector) {
                    this.dom[name] = false;
                }
            });
        }
    }

    /**
     * Write out ur dom bindings (aka. selectors)
     * Remember to query from this.el!
     *
     * Can also return an array to resolve automatically
     * @abstract
     *
     * @example
     * domBindings() {
     *  this.dom.watchlist = this.dom.el.querySelector('.m-jobItem__watchlist');
     *  this.dom.locationLinks = this.dom.el.querySelectorAll('.m-jobItem__locationLink');
     * }
     *
     * @example
     * domBindings() {
     *      return {
     *          items: ['.m-jobsList__jobs'],
     *          button: '.m-jobsList__button',
     *      }
     * }
     */
    domBindings() {}

    /**
     * All UI event handlers are setup in this method.
     * @abstract
     *
     * @example
     * domEvents() {
     *  this.dom.watchlist.addEventListener('click', e=>yolo(e));
     * }
     */
    domEvents() {}

    /**
     * This method runs very early in lifecycle.
     * We can get initial properties from markup or
     * other client sources to create the module state.
     *
     * @abstract
     * @return {Object} state
     *
     * @example
     * getPropsFromDom() {
     *  return {
     *      id: this.dom.el.dataset.id,
     *  };
     * }
     */
    getPropsFromDom() {}

    /**
     * This is the place where we create our submodule instances
     * @abstract
     *
     * @example
     * subs() {
     *   this.bentoDashboard = new BentoDashboard(this.dom.bentoDashboard);
     * }
     */
    subs() {}

    /**
     * Subscribe to events for sub modules
     * @abstract
     * @example
     * subEvents() {
     *  this.jobsSearchform.events.on('submit',
     *    searchRequest => this.newSearchRequest(searchRequest.arguments[0]));
     * }
     */
    subEvents() {}

    /**
     * Pass data to submodules. This is the js equivalent
     * to the template data context.
     *
     * @abstract
     * @example
     * subsProps() {
     *   this.jobsList.setProps(this.props.jobsList, true);
     * }
     */
    subsProps() {}

    /**
     * Setup a modules template. Pass the imported template to the class context
     * @todo can we solve this more easy? This is freakin boilerplate
     * @abstract
     * @example
     * setTemplate {
     *  this.template = Template;
     * }
     */
    setTemplate() {}

    /**
     * Called after module is initialized. Everything is ready now!
     * @abstract
     */
    ready() {}

    /**
    * Is called if this.dom.el enters the viewport
    * @abstract
     */
    entersViewport() {}

    /**
     * Is called when modules receives different props from set/updateProps
     */
    onPropsChanged() {}

    /**
     * Is called after the module has been rendered
     */
    afterRender() {}

    /**
     * Propagate event
     */
    propagateEvents(module, eventName) {
        if (!module) return;

        module.events.on(eventName, (e) => {
            this.events.emit(eventName, ...e.arguments);
        });
    }

    /**
     * Use for validation needs (child or component based)
     * @abstract
     */
    validationRules() {}

    /**
     * Shorthand for this.validation.validate()
     * @returns {boolean}
     */
    validate() {
        return this.validation.validate();
    }
}
