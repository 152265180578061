import { AppModule } from 'app/module';
import { ArticleCard } from 'module/articleCard/main';
import { ArticleCardIndex } from 'module/articleCardIndex/main';
import { Slider } from 'module/slider/main';
import { mediaQueries } from 'util/mediaQueries';
import './styles.scss';

export class ArticleCardSlider extends AppModule {
    domBindings() {
        return {
            slider: '.m-slider',
            articleCards: ['.m-articleCard'],
        };
    }

    domEvents() {
        mediaQueries.events.on('changed', () => {
            if (!mediaQueries.isDesktop()) {
                this.initSlider();
            } else {
                this.destroySlider();
            }
        });
    }

    subs() {
        if (!mediaQueries.isDesktop()) {
            this.initSlider();
        }

        const { index } = this.getPropsFromDom();
        this.updateProps({ index });

        const ArticleCardClass = this.props.index ? ArticleCardIndex : ArticleCard;

        this.articleCards = [];
        this.dom.articleCards.forEach((articleCard) => {
            this.articleCards.push(new ArticleCardClass(articleCard));
        });
    }

    initSlider() {
        if (!this.slider) {
            this.slider = new Slider(this.dom.slider);
            this.slider.setup();
        }
    }

    destroySlider() {
        if (this.slider) {
            this.slider.destroy();
            this.slider = null;
        }
    }

    getPropsFromDom() {
        return {
            index: this.dom.el.classList.contains('m-articleCardSlider--index'),
        };
    }
}
