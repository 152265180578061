function setCookie(key, value, lifeSpan, path, secure = false, domain = null) {
    const values = [
        encodeURIComponent(value),
    ];

    // Why don't we use `max-age`? Seems to be K2 legacy...
    if (lifeSpan) {
        values.push(`expires=${new Date(Date.now() + lifeSpan).toUTCString()}`);
    }

    if (path) {
        values.push(`path=${path}`);
    }

    if (secure) {
        values.push('secure=true');
    }

    if (domain !== null) {
        values.push(`domain=${domain}`);
    }

    document.cookie = `${key}=${values.join(';')}`;
}

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return decodeURIComponent(parts.pop().split(';').shift());
    }
    return null;
}

function removeCookie(key, domain = null) {
    setCookie(key, '', 'Thu, 01 Jan 1970 00:00:00 UTC', '/', false, domain);
}

function getCookieDomain(prefix = '.') {
    const host = window.location.hostname.split('.');

    if (host.length > 3) { // Is needed for staging only
        host.splice(0, 2);
    } else if (host.length > 2) { // Is needed for local url (k4.test)
        host.splice(0, 1);
    }

    return host.length === 1 ? window.location.hostname : (prefix + host.join('.'));
}

export { getCookie, setCookie, removeCookie, getCookieDomain };
