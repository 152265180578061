import Dispatcher from 'dispatcherjs';

class UserNotifications {
    constructor() {
        this.notifications = {
            cv: false,
            applications: false,
            messages: false,
            alarm: false,
            jobs: false,
            matching: false,
            companies: false,
        };
        this.events = new Dispatcher();
    }

    update(notifications) {
        this.fetch = true;
        this.notifications = notifications;
        this.events.emit('update');
    }
}

// Singleton
export const userNotifications = new UserNotifications();
