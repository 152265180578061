// REGEX from Symfony's EmailValidator and added umlauts
// eslint-disable-next-line
export const REGEX_EMAIL = /^[öäüÖÄÜßa-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[öäüÖÄÜßa-zA-Z0-9](?:[öäüÖÄÜßa-zA-Z0-9-]{0,61}[öäüÖÄÜßa-zA-Z0-9])?(?:\.[öäüÖÄÜßa-zA-Z0-9](?:[öäüÖÄÜßa-zA-Z0-9-]{0,61}[öäüÖÄÜßa-zA-Z0-9])?)*$/;
// copy pasta from our friend jquery plugins
// https://cdn.jsdelivr.net/jquery.validation/1.15.0/jquery.validate.js
export const REGEX_PASSWORD = /(?=.*[^a-z])(?=.*[a-z]).{8,}/;
export const REGEX_INTEGER = /^[0-9]+$/;
export const REGEX_NUMERIC = /^\d*(.|,)?\d{1,2}?$/;
export const REGEX_WORK_HOURS = /^\d{1,2}((.|,)?([5,0]))?$/;
export const REGEX_JOBSEARCH_CONTROLLER = /(?!\/(jobs\/)[0-9]{5,}($|\?))\/jobs\/.*$/;
