var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "_label";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "_label";
},"5":function(container,depth0,helpers,partials,data) {
    return "tabindex=\"0\"";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0));
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0));
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-event=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"event") : depth0), depth0))
    + "\"";
},"13":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"15":function(container,depth0,helpers,partials,data) {
    return "checked";
},"17":function(container,depth0,helpers,partials,data) {
    return " m-checkbox__checkbox--error";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"m-checkbox__label\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tinyLabel") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":23,"column":60}}})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFontLight") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":24,"column":63}}})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFontGrey") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":25,"column":61}}})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDarkGrey") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":26,"column":65}}})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDarkGreyMobile") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":27,"column":77}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0)) != null ? stack1 : "")
    + "</span>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return " m-checkbox__label--tiny";
},"22":function(container,depth0,helpers,partials,data) {
    return " m-checkbox__label--light";
},"24":function(container,depth0,helpers,partials,data) {
    return " m-checkbox__label--grey";
},"26":function(container,depth0,helpers,partials,data) {
    return " m-checkbox__label--darkGrey";
},"28":function(container,depth0,helpers,partials,data) {
    return " m-checkbox__label--darkGreyMobile";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"m-checkbox__validationMessageOutlet\">\n"
    + ((stack1 = container.invokePartial(require("../validationMessage/template.hbs"),depth0,{"name":"module/validationMessage/template","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label class=\"m-checkbox\"\n       id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":11},"end":{"line":2,"column":90}}})) != null ? stack1 : "")
    + "\"\n       "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"ariaHidden") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":7},"end":{"line":3,"column":52}}})) != null ? stack1 : "")
    + "\n       for=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":79}}})) != null ? stack1 : "")
    + "\"\n       aria-hidden=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"ariaHidden") : depth0), depth0))
    + "\">\n    <input\n        name=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n        id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":79}}})) != null ? stack1 : "")
    + "\"\n        type=\"checkbox\"\n        value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\"\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"event") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":11,"column":50}}})) != null ? stack1 : "")
    + "\n        class=\"m-checkbox__input\"\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":13,"column":39}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"checked") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":14,"column":37}}})) != null ? stack1 : "")
    + "\n        data-qa=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n        data-count=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"count") : depth0), depth0))
    + "\"\n    >\n"
    + ((stack1 = container.invokePartial(require("../icon/files/dls/checkbox--s.hbs"),depth0,{"name":"module/icon/files/dls/checkbox--s","hash":{"class":"m-checkbox__check","size":"small"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <span class=\"m-checkbox__checkbox"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"error") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":37},"end":{"line":20,"column":85}}})) != null ? stack1 : "")
    + "\" data-qa=\"checkbox\"></span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":28,"column":11}}})) != null ? stack1 : "")
    + "</label>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"explicitValidationMessageOutlet") : depth0),{"name":"unless","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":0},"end":{"line":35,"column":11}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});