import { AppModule } from 'app/module';
import { app } from 'app/app';
import Template from './template.hbs';
import './styles.scss';

export class Base64Link extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        return {
            a: '.m-base64Link__a',
        };
    }

    ready() {
        app.events.on('firstUserInteraction', () => {
            if (this.dom.a.getAttribute('data-link')) {
                this.dom.a.href = window.atob(this.dom.a.getAttribute('data-link').replace(/\s/g, ''));
            }
        });
    }
}
