import { AppModule } from 'app/module';
import { mediaQueries } from 'util/mediaQueries';
import './styles.scss';

export class Footer extends AppModule {
    domBindings() {
        return {
            groupHeaders: ['.m-footer__groupHeader'],
        };
    }

    domEvents() {
        for (let i = 0; i < this.dom.groupHeaders.length; i += 1) {
            this.dom.groupHeaders[i].addEventListener('click', this.changedAccordion);
        }
    }

    changedAccordion(e) {
        if (mediaQueries.isMobile()) {
            if (e.currentTarget.parentNode.classList.contains('m-footer__container--open')) {
                e.currentTarget.parentNode.classList.remove('m-footer__container--open');
            } else {
                e.currentTarget.parentNode.classList.add('m-footer__container--open');
            }
        }
    }
}
