function isInViewport(el) {
    const IS_IN_VIEWPORT_THRESHOLD = 200;
    const rect = el.getBoundingClientRect();

    return (rect.top - IS_IN_VIEWPORT_THRESHOLD) < (window.innerHeight || document.body.clientHeight)
        && (rect.left - IS_IN_VIEWPORT_THRESHOLD) < (window.innerWidth || document.body.clientWidth);

    // @todo add bottom + left trashhold
    // @todo is left actually needed?
}

export { isInViewport };
