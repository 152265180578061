import localforage from 'localforage';

export default class LocalForageDriver {
    constructor(config) {
        const driverConfig = {
            name: 'user-activity-history/v1',
            driver: localforage.LOCALSTORAGE,
            ...config,
        };
        this.store = localforage.createInstance(driverConfig);
    }

    async setItem(key, value, callback) {
        return this.store.setItem(key, value, callback);
    }

    async getItem(key, callback) {
        return this.store.getItem(key, callback);
    }

    async removeItem(key, callback) {
        return this.store.removeItem(key, callback);
    }

    async length(callback) {
        return this.store.length(callback);
    }

    async keys(callback) {
        return this.store.keys(callback);
    }

    /**
     *
     * @param iterator function(value, key, iterationNumber))
     * @param resultCallback function(lastIteratedPair)
     * @returns {Promise<unknown>}
     */
    async iterate(iterator, resultCallback) {
        return this.store.iterate(iterator, resultCallback);
    }

    async clear(callback) {
        return this.store.clear(callback);
    }
}
