import { ValidatableInput } from 'app/validatableInput';
import 'module/icon/main';
import { readScreenReaderText } from 'util/a11y';
import { KEYCODE_ENTER } from 'app/keyCodes';
import Template from './template.hbs';
import './styles.scss';

export class Checkbox extends ValidatableInput {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        this.dom.input = this.dom.el.querySelector('.m-checkbox__input');
        this.dom.label = this.dom.el.querySelector('.m-checkbox__label');
        this.dom.validationMessageOutlet = this.dom.el.parentElement.querySelector(
            '.m-checkbox__validationMessageOutlet',
        );
    }

    domEvents() {
        this.dom.input.addEventListener('click', (e) => this.toggle(e));
        this.dom.el.addEventListener('keydown', (e) => {
            if (!(e.key === KEYCODE_ENTER) && !(e.key === ' ')) return;
            e.preventDefault();
            this.toggle(e);
        });
    }

    getPropsFromDom() {
        return {
            ariaHidden: this.dom.el.getAttribute('aria-hidden') ?? false,
            value: this.dom.input.value,
            id: this.dom.input.id,
            name: this.dom.input.name,
            checked: this.dom.input.checked,
            disabled: this.dom.input.disabled,
            label: this.dom.label ? this.dom.label.innerHTML : null,
            isFontLight: this.dom.label ? this.dom.label.classList.contains('m-checkbox__label--light') : false,
            isFontGrey: this.dom.label ? this.dom.label.classList.contains('m-checkbox__label--grey') : false,
            isDarkGrey: this.dom.label ? this.dom.label.classList.contains('m-checkbox__label--darkGrey') : false,
            tinyLabel: this.dom.label ? this.dom.label.classList.contains('m-checkbox__label--tiny') : false,
            count: this.dom.input.getAttribute('data-count'),
        };
    }

    getValue() {
        return this.dom.input.checked;
    }

    toggle(e) {
        e.stopPropagation();
        this.updateProps({ checked: !this.props.checked }, true);
        this.events.emit('toggle', e, this.props.checked);

        if (this.props.ariaHidden) return;
        const textToRead = this.props.checked ? 'aktiviert' : 'deaktiviert';
        readScreenReaderText(textToRead);
    }
}
