import { getCookie, removeCookie } from './cookie';

export const CONSENT_GROUPS = {
    necessary: 'necessary',
    performance: 'performance',
    marketing: 'marketing',
};
const CONSENT_GROUPS_ONE_TRUST = {
    [CONSENT_GROUPS.necessary]: 'C0001',
    [CONSENT_GROUPS.performance]: 'C0002',
    [CONSENT_GROUPS.marketing]: 'C0004',
};
const CONSENT_COOKIE_NAME = 'OptanonConsent';
const CONSENT_COOKIE_ALERT_NAME = 'OptanonAlertBoxClosed';

const commandStack = new Set();
function queueFunctionCall(functionCall) {
    return new Promise((resolve) => {
        if (window.OneTrust) {
            resolve(functionCall());
        } else {
            commandStack.add({
                method: functionCall,
                resolve,
            });
        }
    });
}
const onChangeCallbacks = new Set();
const onInitializationCallbacks = new Set();
queueFunctionCall(() => window.OneTrust.OnConsentChanged(() => onChangeCallbacks.forEach((cb) => cb())));
const consentDriver = {
    openSettings() {
        return queueFunctionCall(() => window.OneTrust.ToggleInfoDisplay());
    },
    revoke() {
        // We can't use OneTrust's `RejectAll` method here because we don't load OneTrust on all pages.
        removeCookie(CONSENT_COOKIE_NAME);
        removeCookie(CONSENT_COOKIE_ALERT_NAME);
        onChangeCallbacks.forEach((cb) => cb());
    },
    onChange(callback) {
        onChangeCallbacks.add(callback);
        const removeCallback = () => onChangeCallbacks.delete(callback);

        return removeCallback;
    },
    onInitialization(callback) {
        onInitializationCallbacks.add(callback);
        const removeCallback = () => onInitializationCallbacks.delete(callback);

        return removeCallback;
    },
};

function runInitializationCallbacks() {
    onInitializationCallbacks.forEach((cb) => cb());
    onInitializationCallbacks.clear();
}

function runCommands() {
    commandStack.forEach(({ method, resolve }) => resolve(method()));
    commandStack.clear();
}

let OneTrustOriginal;
Object.defineProperty(window, 'OneTrust', {
    get() {
        return OneTrustOriginal;
    },
    set(value) {
        OneTrustOriginal = value;
        if (value) {
            const WAIT_FOR_INIT_TIMEOUT = 100;
            setTimeout(() => {
                runInitializationCallbacks();
                runCommands();
            }, WAIT_FOR_INIT_TIMEOUT);
        }
    },
    configurable: true,
});

function getGroupsGranted() {
    const cookie = getCookie(CONSENT_COOKIE_NAME);
    const params = new URLSearchParams(cookie);
    const groupsRaw = params.get('groups');

    if (!groupsRaw) return [];

    return groupsRaw.split(',')
        .filter((groupRaw) => groupRaw.includes(':1'))
        .map((groupRaw) => groupRaw.replace(':1', ''));
}

export function hasConsent(group = null) {
    // Enable all consents for local development to surpass any negative side effects that could occur
    // when something at the local setup is not working (e.g. --noPointerEvents class set on iframes)
    if (APP_FORCE_CONSENT) return true;

    const groupsGranted = getGroupsGranted();
    if (group) return groupsGranted.includes(CONSENT_GROUPS_ONE_TRUST[group]);

    const groupsGrantedAndRevokable = groupsGranted
        .filter((g) => g !== CONSENT_GROUPS_ONE_TRUST[CONSENT_GROUPS.necessary]);

    return groupsGrantedAndRevokable.length > 0;
}

export function hasConsentGrantedOrDenied() {
    return hasConsent(CONSENT_GROUPS.necessary);
}

export function openConsentSettings() {
    return consentDriver.openSettings();
}

export function revokeConsent() {
    return consentDriver.revoke();
}

export function onConsentChange(callback) {
    consentDriver.onChange(callback);
}

function onConsentInitialization(callback) {
    consentDriver.onInitialization(callback);
}

export function afterConsentInitialization(callback) {
    if (window.OneTrust) {
        callback();
        return;
    }

    onConsentInitialization(callback);
}
