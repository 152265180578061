import { AppModule } from 'app/module';

import Template from './template.hbs';
import './styles.scss';

export class NotificationBar extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        return {
            close: '.m-notificationBar__close',
        };
    }

    getPropsFromDom() {
        return {
            insideModal: this.dom.el.classList.contains('m-notificationBar--insideModal'),
        };
    }

    domEvents() {
        if (this.dom.close) {
            this.dom.close.addEventListener('click', () => this.hide());
        }
    }

    hide() {
        this.updateProps({ visible: false }, true);
        this.events.emit('hide');
    }
}
