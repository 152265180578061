/**
 * @module util/form
 */
const rCRLF = /\r?\n/g;
const rWhitespaces = /^\s+|\s+$/g;
const rSubmitterTypes = /^(?:submit|button|image|reset|file)$/i;
const rCheckableType = /^(?:checkbox|radio)$/i;
const rSubmittable = /^(?:input|select|textarea|keygen)/i;

/**
 * Encode a set of form elements as an array of names and values.
 * @param $form HTMLFormElement
 * @returns {Array<Object>}
 */
function toJSON($form) {
    if (!$form) {
        return [];
    }
    return [].slice.call($form.elements)
        .filter((element) => element.name
            && !element.disabled
            && rSubmittable.test(element.nodeName)
            && !rSubmitterTypes.test(element.type)
            && (element.checked || !rCheckableType.test(element.type)))
        .map((element) => ({
            name: element.name,
            value: element.value ? element.value.replace(rCRLF, '\r\n') : null,
        }));
}

/**
 * Encode a set of form elements as a string for submission.
 * @param $form HTMLFormElement
 * @returns {string}
 */
function serialize($form) {
    const params = [];
    toJSON($form).forEach((element) => {
        params.push([
            encodeURIComponent(element.name),
            encodeURIComponent(!element.value ? '' : element.value),
        ].join('='));
    });
    return params.join('&');
}

/**
 * Return all required fields that are empty.
 * @param $form HTMLFormElement
 * @returns {Array<HTMLElement>}
 */
function testRequired($form) {
    if (!$form) {
        return [];
    }
    return [].slice.call($form.elements)
        .filter((element) => (element.getAttribute('required')
                || element.getAttribute('aria-required'))
                && !element.value.replace(rWhitespaces, '').length);
}

/**
 * Return serialized form-data
 * @param $form HTMLFormElement
 * @param doTrim bool
 * @param toLower bool
 * @returns {{}}
 */
function serializeJson($form, doTrim, toLower) {
    const $inputs = $form.elements;
    const inputsLength = $inputs.length;
    const formData = {};
    let i;

    for (i = 0; i < inputsLength; i += 1) {
        const $input = $inputs[i];

        if ($inputs[i] && $inputs[i].type !== undefined && $input.name && $input.name !== '') {
            let value = doTrim ? (($input.value).toString().trim()) : $input.value;
            value = toLower ? value.toLowerCase() : value;

            const isFormArray = ($input.name.substr($input.name.length - 2) === '[]');
            const isUncheckedCheckbox = (
                ($input.type === 'checkbox' || $input.type === 'radio')
                && !$input.checked
            );

            if (isFormArray) {
                if (!(formData[$input.name] instanceof Array)) {
                    formData[$input.name] = [];
                }

                if (!isUncheckedCheckbox) {
                    if ($input.type === 'select-multiple' && $input.options) {
                        for (let j = 0; j < $input.options.length; j += 1) {
                            if ($input.options[j].selected) {
                                formData[$input.name].push($input.options[j].value);
                            }
                        }
                    } else {
                        formData[$input.name].push(value);
                    }
                }
            } else {
                if (isUncheckedCheckbox) {
                    value = 0;
                }

                formData[$input.name] = value;
            }
        }
    }

    return formData;
}

export { serialize, toJSON, testRequired, serializeJson };
