import {
    ANALYTICS_GA4_EVENTS_COOKIE,
} from 'app/cookies';
import { getCookie, getCookieDomain, removeCookie } from 'util/cookie';

export function trackGa4(params) {
    if (typeof window.dataLayer !== 'object') {
        window.dataLayer = window.dataLayer || [];
    }
    if (window.dataLayer) {
        window.dataLayer.push(
            params,
        );
    }
}

// TODO refactor components to use this function instead of manual document.documentElement.getAttribute(...) calls
export function getTrackingCategory() {
    const $html = document.documentElement;
    return $html.getAttribute('data-event-category');
}

function sendPageView(
    title = document.title,
    url = window.location.pathname + window.location.search,
    jobDetail = null,
) {
    if (jobDetail?.isInactive) {
        window.dataLayer.push({
            event: 'pageview',
            pageUrl: '/jobs/inactive-job',
            pageTitle: `Inactive Job: ${jobDetail.id} | karriere.at`,
        });
    } else {
        window.dataLayer.push({
            event: 'pageview',
            pageUrl: url,
            pageTitle: title,
        });
    }
}

function setPageType(pageType = false) {
    if (pageType && window.dataLayer) {
        window.dataLayer.push({
            pageType,
        });
    }
}

function sendServerSideGa4Events() {
    let cookieData = getCookie(ANALYTICS_GA4_EVENTS_COOKIE);
    if (cookieData) {
        cookieData = cookieData.split(';');
        for (let i = 0; i < cookieData.length; i += 1) {
            if (cookieData[i] !== '') {
                const ga4TrackingEvent = JSON.parse(cookieData[i]);
                trackGa4(ga4TrackingEvent);
            }
        }
        removeCookie(ANALYTICS_GA4_EVENTS_COOKIE, getCookieDomain());
    }
}

export {
    sendPageView,
    setPageType,
    sendServerSideGa4Events,
};
