import {
    REGEX_EMAIL,
    REGEX_INTEGER,
    REGEX_NUMERIC,
    REGEX_PASSWORD,
    REGEX_WORK_HOURS,
} from '../app/regularExpressions';

function fieldIsEmpty(fieldValue) {
    return fieldValue.toString().trim() === '' || fieldValue === false;
}

/**
 * @param rule
 * @param fieldValue
 * @returns {boolean}
 */
function checkRule(rule = { name: '', value: true }, fieldValue = '') {
    const isEmpty = fieldIsEmpty(fieldValue);

    switch (rule.name) {
    case 'required':
        return !isEmpty;

    case 'email':
        return REGEX_EMAIL.test(fieldValue);

    case 'password':
        return isEmpty || REGEX_PASSWORD.test(fieldValue);

    case 'min':
        return fieldValue.length >= parseInt(rule.value, 10);

    case 'max':
        return fieldValue.length <= parseInt(rule.value, 10);

    case 'min_numeric':
        return fieldValue >= parseInt(rule.value, 10);

    case 'max_numeric':
        return fieldValue <= parseInt(rule.value, 10);

    case 'integer':
        return isEmpty || REGEX_INTEGER.test(fieldValue);

    case 'numeric':
        return isEmpty || REGEX_NUMERIC.test(fieldValue);

    case 'range': {
        const [rangeStart, rangeEnd] = rule.value.map((x) => parseInt(x, 10));
        return parseInt(fieldValue, 10) >= rangeStart && parseInt(fieldValue, 10) <= rangeEnd;
    }

    case 'rangeFloat': {
        const [rangeStart, rangeEnd] = rule.value.map((x) => parseInt(x, 10));
        return parseFloat(fieldValue) >= rangeStart && parseFloat(fieldValue) <= rangeEnd;
    }

    case 'rangeWorkHours': {
        const [rangeStart, rangeEnd] = rule.value.map((x) => parseInt(x, 10));
        return REGEX_WORK_HOURS.test(fieldValue) && parseFloat(fieldValue) >= rangeStart
            && parseFloat(fieldValue) <= rangeEnd;
    }

    case 'maxExperience':
        return REGEX_INTEGER.test(fieldValue) && fieldValue <= parseInt(rule.value, 10);

    default:
        return true;
    }
}

/**
 * Possible rule formats:
 * - rule_name@field_name
 * - rule_name@field_name:rule_value
 * - rule_name@field_name:rule_value1|rule_value2|...
 *
 * @param rule
 * @returns {{specificName: string, name: string, value: Array}}
 */
function parseRule(rule) {
    const ruleData = rule.split(':');
    const specificName = ruleData[0];
    const name = specificName.split('@')[0];
    let value = ruleData[1] ? ruleData[1].split('|') : true;
    value = value.length === 1 ? value[0] : value;

    return {
        specificName,
        name,
        value,
    };
}

/**
 * @param fieldValue
 * @param rules
 * @returns {Array}
 */
function validateValue(fieldValue = '', rules = []) {
    const errors = [];

    rules.forEach((ruleString) => {
        const { specificName, name, value } = parseRule(ruleString);

        if (!checkRule({ name, value }, fieldValue)) {
            errors.push({ [specificName]: value });
        }
    });

    return errors;
}

export { checkRule, fieldIsEmpty, parseRule, validateValue };
