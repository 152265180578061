import Dispatcher from 'dispatcherjs';
import { app } from 'app/app';
import { user } from 'app/user';
import { deleteJson, getJson, postJson } from 'util/xhr';
import { unauthorizedError } from 'util/auth';
import { trackGa4 } from 'util/googleAnalytics';
import {
    API_ENDPOINT_COMPANYFOLLOW_ADD,
    API_ENDPOINT_COMPANYFOLLOW_IDS,
    API_ENDPOINT_COMPANYFOLLOW_REMOVE,
} from 'app/endpoints';

/**
 * Singleton userCompanyFollow
 *
 * has all the followed company ids on client.
 */
class UserCompanyFollow {
    constructor() {
        this.events = new Dispatcher();
        this.events.setMaxListeners(30); // there can be 30 listerners max (aka 2 pages of jobs);

        this.ids = null;
        this.fetch = null;
    }

    fetchAll() {
        // dont do stuff if not logged in.
        if (!user.isLoggedIn()) return false;

        // stop if we already do a fetch
        if (this.fetch) return false;

        this.fetch = getJson(
            API_ENDPOINT_COMPANYFOLLOW_IDS,
            {},
            (response) => this.onResponse(response),
            (error) => this.onError(error),
        );

        return true;
    }

    onResponse(response) {
        this.fetch = false;
        this.ids = response.ids;
        this.events.emit('onResponse');
    }

    onError(error) {
        this.fetch = false;
        this.ids = null;
        this.events.emit('onError', { error });
    }

    onFollowError(error) {
        if (user.isLoggedIn()) {
            this.events.emit('onFollowError', error);
        } else {
            const companyFollowObject = unauthorizedError(error);
            this.events.emit('onUnauthorized', { companyFollowObject });
        }
    }

    onFollowResponse(companyId) {
        if (!Array.isArray(this.ids)) return false;

        this.ids.push(companyId);
        this.events.emit('onAddJob');

        const eventCategory = document.documentElement.getAttribute('data-event-category');
        const event = 'company_follow_success';

        if (eventCategory === 'page: companies') {
            trackGa4({
                event,
                company_id: companyId ?? null,
                method: 'Company Search',
            });
        } else {
            trackGa4({
                event,
                company_id: companyId ?? null,
                method: 'Company Profile',
            });
        }

        return true;
    }

    followCompany(companyId) {
        postJson(
            `${API_ENDPOINT_COMPANYFOLLOW_ADD}/${companyId}`,
            {},
            () => this.onFollowResponse(companyId),
            (error) => this.onFollowError(error),
        );

        return true;
    }

    unfollowCompany(companyId) {
        deleteJson(
            `${API_ENDPOINT_COMPANYFOLLOW_REMOVE}/${companyId}`,
            {},
            false,
            (error) => unauthorizedError(error),
        );

        if (this.ids === null) return false;
        const index = this.ids.indexOf(companyId);
        if (!Array.isArray(this.ids) && index === -1) return false;
        this.ids.splice(index, 1);
        this.events.emit('onRemoveJob');
        return true;
    }

    isFollowed(companyId) {
        if (!Array.isArray(this.ids)) return false;

        return this.ids.indexOf(companyId) > -1;
    }
}

export const userCompanyFollow = new UserCompanyFollow();
app.userCompanyFollow = userCompanyFollow;
