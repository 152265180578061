var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"m-loginModal__overlay"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"visible") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":41},"end":{"line":3,"column":94}}})) != null ? stack1 : "")
    + "\"></div>\n        <div class=\"m-loginModal__content\">\n            <div class=\"m-loginModal__contentInner"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"visible") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":50},"end":{"line":5,"column":108}}})) != null ? stack1 : "")
    + "\">\n                <div class=\"m-loginModal__box\">\n                    <div class=\"m-loginModal__scene\">\n                        <div class=\"m-loginModal__sceneImageContainer\">\n                            <img src=\"/assets/images/heroApplication/claim.svg\" alt=\"Den Sprung ins kalte Wasser wagen. Einen neuen Job mit echten Chancen finden.\n                    Willst du, kannst du. karriere.at\" class=\"m-loginModal__image\">\n                            <ul class=\"k-c-list m-loginModal__list\">\n                                <li class=\"k-c-list__item\">\n"
    + ((stack1 = container.invokePartial(require("../icon/files/dls/check--s.hbs"),depth0,{"name":"module/icon/files/dls/check--s","hash":{"class":"k-c-list__icon"},"data":data,"indent":"                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                    <span class=\"k-c-list__text\"><b class=\"m-loginModal__bold\">Passende Jobs</b> per Mail erhalten</span>\n                                </li>\n                                <li class=\"k-c-list__item\">\n"
    + ((stack1 = container.invokePartial(require("../icon/files/dls/check--s.hbs"),depth0,{"name":"module/icon/files/dls/check--s","hash":{"class":"k-c-list__icon"},"data":data,"indent":"                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                    <span class=\"k-c-list__text\">Lebenslauf anlegen & direkt von Unternehmen<b class=\"m-loginModal__bold\"> kontaktiert werden</b></span>\n                                </li>\n                                <li class=\"k-c-list__item\">\n"
    + ((stack1 = container.invokePartial(require("../icon/files/dls/check--s.hbs"),depth0,{"name":"module/icon/files/dls/check--s","hash":{"class":"k-c-list__icon"},"data":data,"indent":"                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                    <span class=\"k-c-list__text\"><b class=\"m-loginModal__bold\">Jobs speichern</b> und einfach bewerben</span>\n                                </li>\n                            </ul>\n                        </div>\n                    </div>\n                    <div class=\"m-loginModal__container\">\n                        <div class=\"m-loginModal__iFrameContainer\">\n                            <button class=\"m-loginModal__close k-c-modal__closeIcon\" aria-label=\"Login Fenster schließen\">\n"
    + ((stack1 = container.invokePartial(require("../icon/files/dls/cross--s.hbs"),depth0,{"name":"module/icon/files/dls/cross--s","hash":{"size":"small"},"data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                            </button>\n                            <iframe class=\"m-loginModal__iFrame\" src=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"src") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":32,"column":70},"end":{"line":32,"column":182}}})) != null ? stack1 : "")
    + "\" frameborder=\"0\" scrolling=\"no\"></iframe>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " m-loginModal__overlay--animate";
},"4":function(container,depth0,helpers,partials,data) {
    return " m-loginModal__contentInner--animate";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"src") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasParams") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":32,"column":88},"end":{"line":32,"column":140}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "&isModal=1";
},"9":function(container,depth0,helpers,partials,data) {
    return "?isModal=1";
},"11":function(container,depth0,helpers,partials,data) {
    return "/user/login/oauth?isModal=1";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"m-loginModal\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"visible") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":38,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});