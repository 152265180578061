import { AppModule } from 'app/module';
import { Slider } from 'module/slider/main';
import { mediaQueries } from 'util/mediaQueries';
import './styles.scss';

export class InterestingCompanies extends AppModule {
    domBindings() {
        return {
            slider: '.m-slider',
        };
    }

    domEvents() {
        mediaQueries.events.on('changed', () => {
            if (!mediaQueries.isDesktop()) {
                this.initSlider();
            } else {
                this.destroySlider();
            }
        });
    }

    subs() {
        if (!mediaQueries.isDesktop()) {
            this.initSlider();
        }
    }

    initSlider() {
        if (!this.slider) {
            this.slider = new Slider(this.dom.slider);
            this.slider.setup();
        }
    }

    destroySlider() {
        if (this.slider) {
            this.slider.destroy();
            this.slider = null;
        }
    }
}
