import GeneralHistoryClass from 'util/client-side-storage/classes/generalHistoryClass';

const maxHistoryEntries = 6;
const storeName = 'job-search';

export class JobSearchHistoryClass extends GeneralHistoryClass {
    constructor(config) {
        config.storeName = storeName;
        config.maxHistoryEntries = maxHistoryEntries;
        super(config);
    }

    async getKeywords(callback) {
        return await super.getItem('keywords', callback) ?? [];
    }

    async getLocations(callback) {
        return await super.getItem('locations', callback) ?? [];
    }

    async getCombinations(callback) {
        return await super.getItem('combinations', callback) ?? [];
    }

    async addKeyword(keyword, callback) {
        if (!this.historyEnabled()) return false;
        if (keyword.length === 0) return false;

        if (await this.keywordExistsInHistory(keyword)) {
            await this.removeKeywordFromHistory(keyword);
        }

        let keywords = await this.getKeywords();
        keywords.unshift({ keyword, lastVisited: new Date() });
        keywords = keywords.slice(0, this.maxHistoryEntries);

        return this.setItem('keywords', keywords, callback);
    }

    async addLocation(location, callback) {
        if (!this.historyEnabled()) return false;
        if (location.length === 0) return false;

        if (await this.locationExistsInHistory(location)) {
            await this.removeLocationFromHistory(location);
        }

        let locations = await this.getLocations();
        locations.unshift({ location, lastVisited: new Date() });
        locations = locations.slice(0, this.maxHistoryEntries);

        return this.setItem('locations', locations, callback);
    }

    async addCombination(combination, callback) {
        if (!this.historyEnabled()) return false;
        if (combination.location.length === 0 && combination.keyword.length === 0) return false;

        if (await this.combinationExistsInHistory(combination)) {
            await this.removeCombinationFromHistory(combination);
        }

        let combinations = await this.getCombinations();
        const { keyword, location } = combination;
        combinations.unshift({ keyword, location, lastVisited: new Date() });
        combinations = combinations.slice(0, this.maxHistoryEntries);

        return this.setItem('combinations', combinations, callback);
    }

    async add(rawCombination) {
        const { keyword, location } = {
            keyword: rawCombination.keyword.trim(),
            location: rawCombination.location.trim(),
        };
        await this.addKeyword(keyword);
        await this.addLocation(location);
        await this.addCombination({ keyword, location });
        return true;
    }

    async keywordExistsInHistory(keywordToCheckFor) {
        return (await this.getKeywords())
            .find(({ keyword }) => keyword.toLowerCase() === keywordToCheckFor.toLowerCase());
    }

    async locationExistsInHistory(locationToCheckFor) {
        return (await this.getLocations())
            .find(({ location }) => location.toLowerCase() === locationToCheckFor.toLowerCase());
    }

    async combinationExistsInHistory(combination) {
        return (await this.getCombinations())
            .find(({ keyword, location }) => keyword.toLowerCase() === combination.keyword.toLowerCase()
                && location.toLowerCase() === combination.location.toLowerCase());
    }

    async removeKeywordFromHistory(keywordToRemove) {
        const keywords = await this.getKeywords();
        const index = keywords.findIndex(({ keyword }) => keyword.toLowerCase() === keywordToRemove.toLowerCase());
        if (index > -1) {
            keywords.splice(index, 1);
            return this.setItem('keywords', keywords);
        }
        return false;
    }

    async removeLocationFromHistory(locationToRemove) {
        const locations = await this.getLocations();
        const index = locations.findIndex(({ location }) => location.toLowerCase() === locationToRemove.toLowerCase());
        if (index > -1) {
            locations.splice(index, 1);
            return this.setItem('locations', locations);
        }
        return false;
    }

    async removeCombinationFromHistory(combi) {
        const combinations = await this.getCombinations();
        const index = combinations
            .findIndex(({ keyword, location }) => keyword.toLowerCase() === combi.keyword.toLowerCase()
                && location.toLowerCase() === combi.location.toLowerCase());
        if (index > -1) {
            combinations.splice(index, 1);
            return this.setItem('combinations', combinations);
        }
        return false;
    }

    async getKeywordArray(callback) {
        return (await this.getKeywords(callback))
            .reduce((acc, cur) => { acc.push(cur.keyword); return acc; }, []);
    }

    async getLocationArray(callback) {
        return (await this.getLocations(callback))
            .reduce((acc, cur) => { acc.push(cur.location); return acc; }, []);
    }

    async getCombinationArray(callback) {
        return (await this.getCombinations(callback))
            .reduce((acc, { keyword, location }) => { acc.push({ keyword, location }); return acc; }, []);
    }
}
