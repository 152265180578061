import LocalForageDriver from 'util/client-side-storage/drivers/localForage';

// A guard is a function that returns a boolean. If the function returns true, the storage system is enabled.
const defaultGuard = () => true;

export default class GeneralHistoryClass {
    constructor(config) {
        this.driver = new LocalForageDriver(config);
        this.maxHistoryEntries = config.maxHistoryEntries ?? Number.MAX_SAFE_INTEGER;
        this.guard = config.guard ?? defaultGuard;
        this.historyEnabled();
    }

    async setItem(key, value, callback) {
        return this.driver.setItem(key, value, callback);
    }

    async getItem(key, callback) {
        return this.driver.getItem(key, callback);
    }

    /**
     * Aggressively delete all user activity history data by default if the history is no longer enabled.
     * This erases the need to bind consent change listeners for the histories on every page.
     * @returns {boolean}
     */
    historyEnabled() {
        if (!this.guard()) {
            this.deleteHistory();
            return false;
        }
        return true;
    }

    onConsentChange() {
        if (!this) return;
        this.historyEnabled();
    }

    async deleteHistory(callback) {
        return this.driver.clear(callback);
    }
}
