import { AppModule } from 'app/module';
import { mediaQueries } from 'util/mediaQueries';
import { JobsFilterDropdownPill } from 'module/jobsFilterDropdownPill/main';
import { Checkbox } from 'module/checkbox/main';
import { Radio } from 'module/radio/main';
import { readScreenReaderText } from 'util/a11y';
import { KEYCODE_ESCAPE, KEYCODE_ESCAPE_EDGE } from 'app/keyCodes';
import Template from './template.hbs';
import './styles.scss';

const resetSalaryCountId = 10006;

export class JobsFilterDropdown extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        this.dom.checkboxes = this.dom.el.querySelectorAll('.m-checkbox');
        this.dom.radio = this.dom.el.querySelectorAll('.k-c-radio');
        this.dom.groupHeader = this.dom.el.querySelector('.m-jobsFilterDropdown__groupHeader');
        this.dom.mobileHeadline = this.dom.el.querySelector('.m-jobsFilterDropdown__mobileHeadline');
        this.dom.headline = this.dom.el.querySelector('.m-jobsFilterDropdown__headline');
        this.dom.groupHeaderIcon = this.dom.el.querySelector('.m-jobsFilterDropdown__groupHeaderIcon');
        this.dom.jobsFilterDropdownPill = this.dom.el.querySelector('.m-jobsFilterDropdownPill');
        this.dom.itemContainer = this.dom.el.querySelector('.m-jobsFilterDropdown__items');
        this.dom.items = this.dom.el.querySelectorAll('.m-jobsFilterDropdown__item');
        this.dom.locked = this.dom.el.querySelector('.m-jobsFilterDropdown__lock');
        this.dom.isNew = this.dom.el.querySelector('.m-jobsFilterDropdown__new');
        this.dom.dropdownLabel = this.dom.el.querySelector('.m-jobsFilterDropdown__dropdownLabel');
        this.dom.itemInners = this.dom.el.querySelectorAll('.m-jobsFilterDropdown__itemInner');
        this.dom.itemInnerWrappers = this.dom.el.querySelectorAll('.m-jobsFilterDropdown__itemInnerWrapper');
        // Checkbox or Radio instances are needed in se `getPropsFromDom()` which runs before `subs()`.
        this.elements = [].map.call(this.dom.items, ($item) => {
            let element = false;
            const type = this.dom.el.getAttribute('data-type');
            if (type === 'checkbox') {
                element = new Checkbox($item.querySelector('.m-jobsFilterDropdown__itemInner .m-checkbox'));
            } else {
                element = new Radio($item.querySelector('.m-jobsFilterDropdown__itemInner .k-c-radio'));
            }
            return element;
        });
    }

    getPropsFromDom() {
        const props = {
            touched: this.dom.groupHeader.classList.contains('m-jobsFilterDropdown__groupHeader--touched'),
            hideAggregations: (this.dom.el.getAttribute('data-hide-aggregations') === 'true'),
            responsiveLayout: this.dom.el.classList.contains('m-jobsFilterDropdown--responsiveLayout'),
            mobileHeadline: this.dom.mobileHeadline ? this.dom.mobileHeadline.innerText : false,
            headline: this.dom.headline ? this.dom.headline.innerText : false,
            insideModal: this.dom.groupHeader.classList.contains('m-jobsFilterDropdown__groupHeader--insideModal'),
            category: this.dom.el.getAttribute('data-category'),
            isLastDropdown:
                this.dom.groupHeaderIcon.classList.contains('m-jobsFilterDropdown__groupHeaderIcon--noSpacing'),
            radio: this.dom.itemContainer ? this.dom.itemContainer.getAttribute('data-is-radio') : false,
            locked: !!this.dom.locked,
            isNew: !!this.dom.isNew,
            dropdownLabel: this.dom.dropdownLabel ? this.dom.dropdownLabel.innerText : false,
            isGrey: this.dom.el.getAttribute('data-isGrey') === 'true',
        };
        const dropdown = this.serializeDropdown(props);
        const items = this.getToggleProps();
        const display = this.getDisplayProps(dropdown);

        return { items, ...dropdown, ...display, ...props };
    }

    subs() {
        this.jobsFilterDropdownPill = new JobsFilterDropdownPill(this.dom.jobsFilterDropdownPill);
    }

    domEvents() {
        if (this.dom.itemContainer && this.dom.itemContainer.classList.contains('m-jobsFilterDropdown__items--open')) {
            this.dom.itemContainer.addEventListener('keydown', (e) => {
                if (e.key === KEYCODE_ESCAPE || e.key === KEYCODE_ESCAPE_EDGE) {
                    if (this.props.open) this.changedAccordion(e, true);
                }
            });
        }
        if (this.dom.itemContainer && this.dom.itemContainer.classList.contains('m-jobsFilterDropdown__items--open')
            && !mediaQueries.isMobile()) {
            document.addEventListener('click', (e) => {
                if (this.props.open) this.changedAccordion(e, true);
            });
            document.addEventListener('touchstart', (e) => {
                if (this.props.open) this.changedAccordion(e, true);
            });

            const jobIFrame = document.querySelector('.m-jobContent__iFrame');

            if (jobIFrame) {
                let contentDocument = false;
                try {
                    contentDocument = (jobIFrame.contentWindow || jobIFrame.contentDocument).document;
                    contentDocument.addEventListener('click', (e) => {
                        if (this.props.open) this.changedAccordion(e);
                    });
                    contentDocument.addEventListener('touchstart', (e) => {
                        if (this.props.open) this.changedAccordion(e);
                    });
                } catch (e) {
                    // 'Cannot access iframe'
                }
            }
        }
        this.dom.groupHeader.addEventListener('click', (e) => {
            if (e) e.preventDefault();
            e.stopPropagation();
            this.changedAccordion(e);
        });
        this.dom.groupHeader.addEventListener('keydown', (e) => {
            if (e.key === KEYCODE_ESCAPE || e.key === KEYCODE_ESCAPE_EDGE) {
                if (this.props.open) this.changedAccordion(e, true);
                setTimeout(() => this.dom.groupHeader.focus(), 20);
            }
        });
        this.dom.itemInners.forEach((itemInner, index) => {
            itemInner.addEventListener('click', (e) => {
                if (e.target !== itemInner) return;
                this.elements[index].toggle(e);
            });
        });

        this.dom.itemInnerWrappers.forEach((wrapper, index) => {
            wrapper.addEventListener('click', (e) => {
                if (e.target !== wrapper) return;
                e.preventDefault();
                this.elements[index].toggle(e);
            });
        });
    }

    subEvents() {
        for (let i = 0; i < this.elements.length; i += 1) {
            this.elements[i].events.on('toggle', (e) => {
                if (this.props.radio) this.updateRadioProps(i);
                this.changedFilter();

                const toggledOn = e.arguments[1];
                const textToRead = toggledOn ? 'Kriterium aktiviert' : 'Kriterium deaktiviert';
                readScreenReaderText(textToRead);
            });
        }
    }

    getSerializedItems() {
        const items = JSON.parse(this.dom.el.getAttribute('data-query'));
        if (items) {
            return this.serializeItems(this.props, items);
        }

        return [];
    }

    serializeItems(props, items) {
        const formData = {};

        for (let i = 0; i < items.length; i += 1) {
            const category = `${props.category}[]`;
            if (formData[category] === undefined) {
                formData[category] = [];
            }

            if (items[i].checked) {
                formData[category].push(items[i].value);
            }
        }
        return formData;
    }

    serializeDropdown(props) {
        const formData = {};

        for (let i = 0; i < this.elements.length; i += 1) {
            if (!(formData[props.category] instanceof Object)) {
                formData[props.category] = {};
            }
            if (this.elements[i].props.checked) {
                formData[props.category][this.elements[i].props.value] = true;
            }
        }
        return formData;
    }

    getDisplayProps(dropdown) {
        let count = 0;
        Object.keys(dropdown).forEach((category) => {
            if (dropdown[category][resetSalaryCountId]) {
                count = 0;
            } else {
                count += Object.keys(dropdown[category]).length;
            }
        });

        return {
            count,
            open: this.dom.el.classList.contains('m-jobsFilterDropdown--open')
                || this.dom.el.classList.contains('m-jobsFilterDropdown--insideModalOpen'),
        };
    }

    updateRadioProps(index) {
        for (let i = 0; i < this.elements.length; i += 1) {
            if (index === i) {
                this.elements[i].updateProps({ checked: true }, true);
            } else {
                this.elements[i].updateProps({ checked: false });
            }
        }
    }

    getToggleProps() {
        const toggleProps = {};
        for (let i = 0; i < this.elements.length; i += 1) {
            toggleProps[i] = { ...this.elements[i].props, ...this.elements[i].getPropsFromDom() };
        }
        return toggleProps;
    }

    changedAccordion(e, isClickedOutside) {
        if (Object.keys(this.props.items).length === 0) {
            this.events.emit('openOnFirstUserInteraction');
            return;
        }

        if (e) {
            const outsideDropdown = !e.target.classList.contains('m-checkbox')
                && !e.target.classList.contains('m-checkbox__label');
            if (!outsideDropdown) return;
        }

        if (this.props.locked) {
            this.events.emit('openLoginModal');
            return;
        }
        const props = this.getProps();
        if (!mediaQueries.isMobile()) {
            this.events.emit('closeDropdownsClick', isClickedOutside);
        }
        props.open = !props.open;
        props.touched = true;
        this.updateProps(props, true);

        this.events.emit('updateFilterProps', this.props.open, false);

        if (this.props.open) {
            this.dom.groupHeader.focus();
            readScreenReaderText('Dropdown geöffnet.');
        } else {
            readScreenReaderText('Dropdown geschlossen.');
        }
    }

    changedFilter() {
        const toggleProps = this.getToggleProps();
        const dropdown = this.serializeDropdown(this.props);
        const display = this.getDisplayProps(dropdown);
        const props = { items: toggleProps, ...dropdown, ...display };
        this.jobsFilterDropdownPill.updateProps(props, true);
        this.updateProps(props);
        this.events.emit('updateFilterProps', true, true);
    }
}
