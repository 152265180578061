export const splideI18n = {
    prev: 'Vorherige Slide',
    next: 'Nächste Slide',
    first: 'Zur ersten Slide gehen',
    last: 'Zur letzten Slide gehen',
    slideX: 'Gehe zu slide %s',
    pageX: 'Gehe zu Seite %s',
    carousel: 'Karusell',
    slide: 'Slide',
    slideLabel: '%s von %s',
};
