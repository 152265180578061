import { loadScript } from 'util/loadScript';

const SCRIPT_ID = 'youtubeApi';

let youTubeApiLoadingPromise;

export function loadYoutubeApi() {
    if (youTubeApiLoadingPromise) return youTubeApiLoadingPromise;

    youTubeApiLoadingPromise = new Promise((resolve) => {
        loadScript('https://www.youtube.com/iframe_api', SCRIPT_ID);
        window.onYouTubeIframeAPIReady = () => resolve();
    });

    return youTubeApiLoadingPromise;
}
