import { ENDPOINT_USER_LOGIN } from 'app/endpoints';

/**
 * Is called if we get an unauthorized response from xhr request and
 * redirects to login with state (if present)
 *
 * @param {Object} status
 */
export function unauthorizedError(status) {
    let id;
    let state;
    if (status.errors) {
        if (status.errors.length > 0) {
            const error = status.errors[0];

            if (error.meta?.state) {
                state = error.meta.state;
            }

            if (error.detail && error.detail.id) {
                id = error.detail.id;
            }
        }
    }

    return { state: state ? `${ENDPOINT_USER_LOGIN}?state=${state}` : ENDPOINT_USER_LOGIN, id };
}

/**
 * Initiates the OAuth login flow in a hidden iframe.
 * Is called if the heartbeat fails because of a 401 HTTP error.
 */
export function refreshToken() {
    const iframe = document.createElement('iframe');
    iframe.className = 'auth-login-flow-hidden';
    iframe.src = `${ENDPOINT_USER_LOGIN}?isModal=1`;
    iframe.hidden = true;
    iframe.style.display = 'none';
    iframe.sandbox = 'allow-same-origin allow-scripts';

    // If the login flow completed, remove the iframe again
    window.addEventListener('message', (event) => {
        if (event.data === 'userLoginOauthCallback') {
            document.querySelector('.auth-login-flow-hidden')?.remove();
        }
    });

    document.body.appendChild(iframe);
}
