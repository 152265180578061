/**
 * @module util/xhr
 */

import { getCsrfToken } from 'util/csrf';
import { serializeParams } from './url';

/**
 * @param url
 * @param requestSettings
 * @param resolve
 * @param reject
 * @returns XMLHttpRequest
 */
function request(url, requestSettings, resolve, reject) {
    const xhr = new XMLHttpRequest();

    const settings = { method: 'GET',
        headers: { 'X-CSRF-TOKEN': getCsrfToken() },
        data: null,
        dataType: null,
        crossDomain: false,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        onUploadProgress: null,
        ...requestSettings };

    if (!settings.crossDomain && !settings.headers['X-Requested-With']) {
        settings.headers['X-Requested-With'] = 'XMLHttpRequest';
    }

    // FormData must not be serialized
    if (settings.data && !(settings.data instanceof FormData)) {
        settings.headers['Content-Type'] = settings.contentType;

        if (typeof (settings.data) === 'object') {
            settings.data = serializeParams(settings.data);
        }
    }

    xhr.open(settings.method, url);

    if (settings.headers) {
        Object.keys(settings.headers).forEach((header) => xhr.setRequestHeader(header, settings.headers[header]));
    }

    xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) {
            return;
        }

        if (settings.dataType === 'json') {
            let responseJson = null;

            try {
                responseJson = JSON.parse(xhr.responseText);
            } catch (e) {
                // your json is invalid -> will resolve as text
            }

            if (responseJson) {
                if (responseJson.data) {
                    if (typeof resolve === 'function') {
                        resolve(responseJson.data);
                    }
                    return;
                }

                if (responseJson.errors) {
                    if (typeof reject === 'function') {
                        reject({
                            status: xhr.status,
                            statusText: xhr.statusText,
                            errors: responseJson.errors,
                        });
                    }
                    return;
                }
            }
        }

        if (xhr.status >= 200 && xhr.status < 300) {
            if (typeof resolve === 'function') {
                resolve(xhr.responseText);
            }
        } else if (typeof reject === 'function') {
            reject({
                status: xhr.status,
                statusText: xhr.statusText,
            });
        }
    };

    // Not all browsers support upload events
    // @see https://github.com/axios/axios/blob/26b06391f831ef98606ec0ed406d2be1742e9850/lib/adapters/xhr.js#L156
    if (typeof settings.onUploadProgress === 'function' && xhr.upload) {
        xhr.upload.addEventListener('progress', settings.onUploadProgress);
    }

    xhr.send(settings.data ? settings.data : null);
    return xhr;
}

/**
 * @deprecated Use specific request type methods instead (e.g. getJson, postJson)
 * @param url
 * @param argumentSettings
 * @param resolve
 * @param reject
 * @returns XMLHttpRequest
 */
function requestJson(url, argumentSettings, resolve, reject) {
    const settings = argumentSettings || {};
    settings.dataType = 'json';
    return request(url, settings, resolve, reject);
}

function getJson(url, argumentSettings, resolve, reject) {
    const settings = argumentSettings || {};
    settings.dataType = 'json';
    settings.method = 'GET';
    return request(url, settings, resolve, reject);
}

function postJson(url, argumentSettings, resolve, reject) {
    const settings = argumentSettings || {};
    settings.dataType = 'json';
    settings.method = 'POST';
    return request(url, settings, resolve, reject);
}

function patchJson(url, argumentSettings, resolve, reject) {
    const settings = argumentSettings || {};
    settings.dataType = 'json';
    settings.method = 'PATCH';
    return request(url, settings, resolve, reject);
}

function putJson(url, argumentSettings, resolve, reject) {
    const settings = argumentSettings || {};
    settings.dataType = 'json';
    settings.method = 'PUT';
    return request(url, settings, resolve, reject);
}

function deleteJson(url, argumentSettings, resolve, reject) {
    const settings = argumentSettings || {};
    settings.dataType = 'json';
    settings.method = 'DELETE';
    return request(url, settings, resolve, reject);
}

export { request, requestJson, getJson, postJson, patchJson, putJson, deleteJson };
