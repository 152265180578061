import Dispatcher from 'dispatcherjs';
import { user } from 'app/user';
import { userNotifications } from 'app/userNotifications';
import { getJson } from 'util/xhr';
import { API_ENDPOINT_HEARTBEAT } from 'app/endpoints';
import { TIME_HEARTBEAT, TIME_HEARTBEAT_ON_ERROR } from 'app/timings';

class Heartbeat {
    constructor() {
        this.fetch = null;
        this.timeout = null;

        this.events = new Dispatcher();

        if (user.isLoggedIn() && window.self === window.top) {
            this.tick();
        }
    }

    tick() {
        // If there is either a XHR-request in progress or the user is currently not logged-in,
        // skip the request and schedule a new request for later.
        if (this.fetch || !user.isLoggedIn(true)) {
            this.nextTick(TIME_HEARTBEAT);
            return false;
        }

        this.fetch = getJson(
            API_ENDPOINT_HEARTBEAT,
            {},
            (response) => this.onResponse(response),
            (error) => this.onError(error),
        );

        return true;
    }

    onResponse(response) {
        this.fetch = false;

        this.handleNotifications(response.heartbeat);
        this.nextTick(TIME_HEARTBEAT);
    }

    onError(error) {
        this.fetch = false;
        this.events.emit('error', { error });

        // If an error happened (eg ERR_INTERNET_DISCONNECTED, HTTP 500) or the session expired,
        // retry the heartbeat after a certain back-off time.
        this.nextTick(TIME_HEARTBEAT_ON_ERROR);
    }

    handleNotifications(heartbeat) {
        const hasNotifcations = Object.prototype.hasOwnProperty.call(heartbeat, 'notifications');

        if (hasNotifcations) {
            userNotifications.update(heartbeat.notifications);
        }
    }

    nextTick(timeout) {
        if (!this.timeout) {
            this.timeout = window.setTimeout(() => {
                this.timeout = false;
                this.tick();
            }, timeout);
        }
    }
}

// Singleton
export const heartbeat = new Heartbeat();
