import Dispatcher from 'dispatcherjs';
// If you can avoid it, please don't use relative paths.
import { bpTablet, bpDesktop } from '../app/breakpoints';

export class MediaQueries {
    constructor(dispatcher) {
        this.events = dispatcher || new Dispatcher();

        this.createMqs();
        this.mqEvents();

        this.current = false;
        this.breakpointHandler();
    }

    createMqs() {
        this.mqTablet = window.matchMedia(`(min-width: ${bpTablet}px)`);
        this.mqDesktop = window.matchMedia(`(min-width: ${bpDesktop}px)`);
    }

    mqEvents() {
        this.mqTablet.addListener(() => this.breakpointHandler());
        this.mqDesktop.addListener(() => this.breakpointHandler());
    }

    breakpointHandler() {
        let breakpoint = false;

        if (this.mqDesktop.matches) {
            breakpoint = 'desktop';
        } else if (this.mqTablet.matches) {
            breakpoint = 'tablet';
        } else {
            breakpoint = 'mobile';
        }

        if (this.current !== breakpoint) {
            this.current = breakpoint;
            this.events.emit('changed', breakpoint);
        }
    }

    isMobile() {
        return !this.mqTablet.matches && !this.mqDesktop.matches;
    }

    isTablet() {
        return this.mqTablet.matches && !this.mqDesktop.matches;
    }

    isDesktop() {
        return this.mqDesktop.matches;
    }
}

export const mediaQueries = new MediaQueries();
