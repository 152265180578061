/**
 * Load a script by injecting a script tag into the head.
 *
 * @param {string} url
 * @param {string} id  A unique identifier.
 */
function loadScript(url, id) {
    if (document.getElementById(id)) {
        return;
    }

    const tag = document.createElement('script');
    tag.src = url;
    tag.id = id;
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

/**
 * Load a stylesheet by injecting a link tag into the head.
 *
 * @param {string} url
 * @param {string} id  A unique identifier.
 * @param {string} integrity
 */

function loadCss(url, id) {
    if (document.getElementById(id)) {
        return;
    }

    const tag = document.createElement('link');
    tag.rel = 'stylesheet';
    tag.href = url;
    tag.id = id;
    const firstScriptTag = document.getElementsByTagName('link')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

export { loadScript, loadCss };
