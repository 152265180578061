export const KEYCODE_DOWN = 'ArrowDown';
export const KEYCODE_UP = 'ArrowUp';
export const KEYCODE_LEFT = 'ArrowLeft';
export const KEYCODE_RIGHT = 'ArrowRight';
export const KEYCODE_TABULATOR = 'Tab';

export const KEYCODE_ENTER = 'Enter';
export const KEYCODE_ESCAPE = 'Escape';

export const KEYCODE_DOWN_EDGE = 'Down';
export const KEYCODE_UP_EDGE = 'Up';
export const KEYCODE_LEFT_EDGE = 'Left';
export const KEYCODE_RIGHT_EDGE = 'Right';
export const KEYCODE_ESCAPE_EDGE = 'Esc';
