/**
 * @module util/url
 */

const rParams = /[?&]+([^=&]+)=([^&]*)/g;
// `g` modifier leads to an error described in https://stackoverflow.com/a/3891672,
// where the internal pointer of a RegExp object won't get reset, leading to a different result on each test.
const rBrackets = /\[]$/;

function buildUrl(path) {
    return document.body.getAttribute('data-baseurl') + path;
}

/**
 * @param paramsObject
 * @param encoded
 * @returns {string}
 */
function serializeParams(paramsObject, encoded = false) {
    const pairs = [];
    Object.keys(paramsObject).forEach((key) => {
        const name = key;
        const valueParams = paramsObject[key];

        if (Array.isArray(valueParams)) {
            valueParams.forEach((value) => {
                pairs.push({
                    name: rBrackets.test(key) ? name : `${name}[]`,
                    value,
                });
            });
        } else {
            pairs.push({ name, value: valueParams });
        }
    });

    const serializedParams = pairs.map((element) => [
        encoded ? element.name : encodeURIComponent(element.name),
        encoded ? element.value : encodeURIComponent(element.value),
    ].join('='));

    return serializedParams.join('&');
}

/**
 * @param url
 * @param paramsObject
 * @param encodedParamsObject
 * @returns {string}
 */
function setParams(url, paramsObject, encodedParamsObject = false) {
    let glue = '?';
    const encodedParamsAreSet = encodedParamsObject ? Object.keys(encodedParamsObject).length > 0 : false;
    if (url.indexOf('?') >= 0) {
        glue = '&';
    }

    const completeUrl = encodedParamsAreSet
        ? `${url}${glue}${serializeParams(encodedParamsObject, true)}&${serializeParams(paramsObject)}`
        : url + glue + serializeParams(paramsObject);
    return encodedParamsAreSet ? completeUrl.replace(/&$/, '') : completeUrl.replace(/\?$/, '');
}

/**
 * @param url
 * @returns {Object}
 */
function getParams(url) {
    const paramsObject = {};

    url.replace(rParams, (match, keyString, valueString) => {
        let key = decodeURIComponent(keyString);
        const value = decodeURIComponent(valueString);

        if (rBrackets.test(key)) {
            key = key.replace(rBrackets, '');
            if (!paramsObject[key]) {
                paramsObject[key] = [];
            }
            paramsObject[key].push(value);
        } else {
            paramsObject[key] = value;
        }
    });

    return paramsObject;
}

/**
 * getParams() if one argument is passed,
 * setParams() if two arguments is passed
 * @param url
 * @param paramsObject
 * @param encodedParamsObject
 * @returns {string|Object}
 */
function params(url, paramsObject, encodedParamsObject = false) {
    if (paramsObject || encodedParamsObject) {
        return setParams(url, paramsObject, encodedParamsObject);
    }
    return getParams(url);
}

function stringToSlug(string) {
    return encodeURIComponent(string).replace(/%2F/g, ' ');
}

export { buildUrl, params, setParams, getParams, serializeParams, stringToSlug };
