import { AppModule } from 'app/module';
import {
    KEYCODE_ESCAPE,
    KEYCODE_ESCAPE_EDGE,
    KEYCODE_LEFT,
    KEYCODE_LEFT_EDGE,
    KEYCODE_RIGHT,
    KEYCODE_RIGHT_EDGE,
} from 'app/keyCodes';
import { app } from 'app/app';
import { Video } from 'module/video/main';
import Template from './template.hbs';

import './styles.scss';

export class LightBox extends AppModule {
    ready() {
        app.events.on('keyup', (context) => {
            this.keyUpHandler(context.arguments[0].key);
        });
        this.videos = [];
    }

    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        return {
            items: ['.m-lightBox__item'],
            closeTrigger: '.m-lightBox__closeTrigger',
            buttonNext: '.m-lightBox__button--next',
            buttonPrevious: '.m-lightBox__button--previous',
            meta: ['.m-lightBox__meta'],
        };
    }

    domEvents() {
        if (this.dom.closeTrigger) {
            this.dom.closeTrigger.addEventListener('click', () => this.closeLightBoxViaClick());
        }

        if (this.dom.buttonPrevious && this.dom.buttonNext) {
            this.dom.buttonNext.addEventListener('click', () => this.nextImage());
            this.dom.buttonPrevious.addEventListener('click', () => this.previousImage());
        }
    }

    subEvents() {
        if (this.props.active) {
            app.events.on('onWindowResize', () => {
                if (this.dom.items[this.props.currentIndex]
                    && this.dom.items[this.props.currentIndex].querySelector('.m-lightBox__video')) {
                    this.calculateVideoRatio(this.props.currentIndex);
                    this.calculateDescriptionHeight(this.props.currentIndex);
                } else {
                    this.calculateDescriptionHeight(this.props.currentIndex);
                }
            });
        }
    }

    keyUpHandler(keyCode) {
        if (this.props.active) {
            if (keyCode === KEYCODE_LEFT || keyCode === KEYCODE_LEFT_EDGE) {
                this.previousImage();
            } else if (keyCode === KEYCODE_RIGHT || keyCode === KEYCODE_RIGHT_EDGE) {
                this.nextImage();
            } else if (keyCode === KEYCODE_ESCAPE || keyCode === KEYCODE_ESCAPE_EDGE) {
                this.closeLightBoxViaClick();
            }
        }
    }

    openMediaItemViaClick(mobileSrc) {
        const index = this.getIndexForSrc(mobileSrc);

        this.setActiveImageByIndex(index);
        this.events.emit('hideContent');
        this.updateProps({ active: true }, true);

        if (this.props.mediaItems[index].isImage) {
            this.loadImage(index);
        } else {
            this.loadVideo(index);
        }

        this.preloadMediaItems(index);
        this.events.emit('pushState');
    }

    openMediaItem(mobileSrc) {
        const index = this.getIndexForSrc(mobileSrc);

        this.setActiveImageByIndex(index);
        this.events.emit('hideContent');
        this.updateProps({ active: true }, true);

        if (this.props.mediaItems[index].isImage) {
            this.loadImage(index);
        } else {
            this.loadVideo(index);
        }

        this.preloadMediaItems(index);
    }

    getIndexForSrc(mobileSrc) {
        for (let i = 0; i < this.props.mediaItems.length; i += 1) {
            if (this.props.mediaItems[i].mobileSrc === mobileSrc) {
                return i;
            }
        }
        return false;
    }

    nextImage() {
        this.setActiveImageByIndex(this.props.currentIndex + 1);
    }

    previousImage() {
        this.setActiveImageByIndex(this.props.currentIndex - 1);
    }

    setActiveImageByIndex(index) {
        let newIndex = index;

        // next of last
        if (newIndex >= this.props.mediaItems.length) {
            newIndex = 0;
        }

        // previous of first
        if (newIndex < 0) {
            newIndex = this.props.mediaItems.length - 1;
        }

        const { mediaItems } = this.props;
        for (let i = 0; i < this.props.mediaItems.length; i += 1) {
            mediaItems[i].current = (i === newIndex);
        }

        if (this.props.active) {
            this.dom.items[this.props.currentIndex].classList.remove('m-lightBox__item--current');
            if (this.dom.items[this.props.currentIndex].querySelector('.m-lightBox__video')) {
                this.videos[this.props.currentIndex].pauseVideo();
            }
            this.dom.items[newIndex].classList.add('m-lightBox__item--current');
            if (this.dom.items[newIndex].querySelector('.m-lightBox__video')) {
                this.calculateVideoRatio(newIndex);
            }
            this.calculateDescriptionHeight(newIndex);
            this.preloadMediaItems(newIndex);
        }

        this.updateProps({
            currentIndex: newIndex,
            mediaItems,
            hasPagination: (mediaItems.length > 1),
        });

        if (this.props.mediaItems[newIndex].isImage) {
            this.events.emit('image-view', this.props.mediaItems[newIndex]);
        }
    }

    closeLightBox() {
        this.videos = [];
        this.updateProps({
            active: false,
            currentIndex: false,
            lastSrc: this.props.mediaItems[this.props.currentIndex]
                ? this.props.mediaItems[this.props.currentIndex].mobileSrc : false,
        }, true);
        this.events.emit('showContent');
    }

    closeLightBoxViaClick() {
        window.history.back();
    }

    preloadMediaItems(index) {
        const prevIndex = this.getIndex(index - 1);
        const nextIndex = this.getIndex(index + 1);

        if (this.props.mediaItems[prevIndex].isImage) {
            this.loadImage(prevIndex);
        } else {
            this.loadVideo(prevIndex);
        }

        if (this.props.mediaItems[nextIndex].isImage) {
            this.loadImage(nextIndex);
        } else {
            this.loadVideo(nextIndex);
        }
    }

    loadVideo(index) {
        if (!this.videos[index]) {
            const video = this.dom.items[index].querySelector('.m-video');

            if (video) {
                this.calculateVideoRatio(index);
                this.videos[index] = new Video(video);
                this.videos[index].events.on('onReady', () => this.calculateDescriptionHeight(index));

                this.videos[index].events.on('play', () => this.events.emit('video-play', this.videos[index]));
            }
        }
    }

    loadImage(index) {
        const activeImage = this.dom.items[index].querySelector('.m-lightBox__image');
        const tabletSrc = this.dom.items[index].querySelector('.m-lightBox__tabletSrc');
        const mobileSrc = this.dom.items[index].querySelector('.m-lightBox__mobileSrc');

        if (activeImage.src === '') {
            activeImage.addEventListener('load', () => this.calculateDescriptionHeight(index));
            if (this.props.mediaItems[index].tabletSrc) {
                activeImage.src = this.props.mediaItems[index].tabletSrc;
                tabletSrc.srcset = this.props.mediaItems[index].tabletSrc;
                mobileSrc.srcset = this.props.mediaItems[index].mobileSrc;
            } else {
                activeImage.src = this.props.mediaItems[index].mobileSrc;
            }
        }
    }

    calculateDescriptionHeight(index) {
        if (this.dom.items[index]) {
            const activeMediaItem = this.dom.items[index].querySelector('.m-lightbox__mediaItem');
            const meta = this.dom.meta[index];
            if (meta && activeMediaItem.clientHeight) {
                meta.style.marginTop = `${(activeMediaItem.clientHeight / 2)}px`;
                meta.classList.remove('m-lightBox__meta--hidden');
            }
        }
    }

    calculateVideoRatio(index) {
        if (this.dom.items[index]) {
            const videoContainer = this.dom.items[index].querySelector('.m-lightBox__imageContainer');
            const video = this.dom.items[index].querySelector('.m-lightBox__video');

            const possibleWidth = Math.round((videoContainer.offsetHeight / 9) * 16);
            const possibleHeight = Math.round((videoContainer.offsetWidth / 16) * 9);

            if (possibleWidth <= videoContainer.offsetWidth) {
                video.style.height = `${videoContainer.offsetHeight}px`;
                video.style.width = `${possibleWidth}px`;
            } else {
                video.style.height = `${possibleHeight}px`;
                video.style.width = `${videoContainer.offsetWidth}px`;
            }
        }
    }

    getIndex(newIndex) {
        let index = newIndex;
        // next of last
        if (index >= this.props.mediaItems.length) {
            index = 0;
        }

        // previous of first
        if (index < 0) {
            index = this.props.mediaItems.length - 1;
        }

        return index;
    }
}
