import Dispatcher from 'dispatcherjs';
import { user } from 'app/user';
import { getJson, deleteJson, putJson } from 'util/xhr';
import { unauthorizedError } from 'util/auth';
import { trackGa4 } from 'util/googleAnalytics';
import {
    API_ENDPOINT_WATCHLIST_CREATE_OR_UPDATE,
    API_ENDPOINT_WATCHLIST_ENTRIES,
    API_ENDPOINT_WATCHLIST_REMOVE,
    API_ENDPOINT_WATCHLIST_MEMO_UPDATE,
} from 'app/endpoints';

/**
 * Singleton userWatchlist
 *
 * has all the watchlist ids on client.
 */
class UserWatchlist {
    constructor() {
        this.events = new Dispatcher();
        // (pagesize + max pushed-jobs) * 4 different listeners * opened details/list items/login modal
        const maxListeners = (30 + 3) * 4 * 3;
        this.events.setMaxListeners(maxListeners);

        /**
         * all the watchlist entries
         * @type {number[]}
         */
        this.watchlistEntries = null;

        /**
         * all the watchlist ids
         * @type {number[]}
         */
        this.ids = null;

        /**
         * check if a fetchAllIds is currently running
         * @type {boolean}
         */
        this.fetch = null;
    }

    /**
     * fetch all the watchlist ids
     */
    fetchAll() {
        // dont do stuff if not logged in.
        if (!user.isLoggedIn()) return false;

        // stop if we already do a fetch
        if (this.fetch) return false;

        this.fetch = getJson(
            API_ENDPOINT_WATCHLIST_ENTRIES,
            {},
            (response) => this.onResponse(response),
            (error) => this.onError(error),
        );

        return true;
    }

    /**
     * If we get watchlist entries we puth them in watchlistEntries and ids into a separate array for faster conditions
     * @emits {onResponse} - ready to update ui
     */
    onResponse(response) {
        this.fetch = false;
        this.watchlistEntries = [];
        this.ids = [];

        Object.keys(response).forEach((index) => {
            this.watchlistEntries[response[index].jobId] = {
                watchlistId: index,
                memo: response[index].memo,
            };
            this.ids.push(response[index].jobId);
        });

        this.events.emit('onResponse');
    }

    /**
     * if something is broken with fetchall ... we are doomed.
     * @emits {onError} - uhoh watchlist ids fetch is broken
     */
    onError(error) {
        this.fetch = false;
        this.watchlistEntries = null;
        this.ids = null;
        this.events.emit('onError', { error });
    }

    /**
     * if something is broken with adding a job
     * @emits {onUnauthorized} - tell listening modules that the user is unauthorized
     */
    onAddError(error, parentElement) {
        const watchlistObject = unauthorizedError(error);
        if (window.location.protocol !== 'https:') {
            window.location.href = watchlistObject.state;
        } else {
            this.events.emit('onUnauthorized', { watchlistObject, parentElement });
        }
    }

    /**
     * Add a job to the users watchlist
     * Redirects to login if unauthorized
     * @param {number} jobId
     * @param {element} parentElement
     */
    addJob(jobId, parentElement = false) {
        putJson(
            `${API_ENDPOINT_WATCHLIST_CREATE_OR_UPDATE}/${jobId}`,
            {},
            (response) => this.onAddResponse(response),
            (error) => this.onAddError(error, parentElement),
        );
    }

    /**
     * If we get a watchlistEntry we put it in this.watchlistEntries and this.ids
     * @emits {onAddJob} - tell listening modules to update the watchlist uis
     */
    onAddResponse(response) {
        if (!Array.isArray(this.watchlistEntries) && !Array.isArray(this.ids)) return false;
        this.watchlistEntries[response.jobId] = {
            watchlistId: response.watchlistId,
            memo: response.memo,
        };
        this.ids.push(response.jobId);
        this.events.emit('onAddJob', response.jobId);

        const eventCategory = document.documentElement.getAttribute('data-event-category');
        let method = null;

        if (eventCategory === 'page: job') {
            method = 'Standalone';
        } else if (eventCategory === 'page: jobs') {
            method = 'Job Search';
        } else if (eventCategory === 'page: jobalarm') {
            method = 'Job Alarm';
        } else if (eventCategory === 'page: jobmatching') {
            method = 'Job Matching';
        } else if (eventCategory === 'page: watchlist') {
            method = 'Job Watchlist';
        }

        trackGa4({
            event: 'job_save_success',
            job_id: response.jobId ?? null,
            method,
        });

        return true;
    }

    /**
     * Removes a job from users watchlist
     * @param {number} jobId
     */
    removeJob(jobId) {
        const index = this.ids.indexOf(jobId);

        deleteJson(
            `${API_ENDPOINT_WATCHLIST_REMOVE}/${jobId}`,
            {},
            () => this.onRemoveResponse(index, jobId),
            (error) => unauthorizedError(error),
        );
    }

    /**
     * If we remove a watchlistEntry we remove it from this.watchlistEntries and this ids
     * @emits {onRemoveJob} - tell listening modules to update the watchlist uis
     */
    onRemoveResponse(index, jobId) {
        if (!Array.isArray(this.watchlistEntries) && !Array.isArray(this.ids) && index === -1) return false;
        this.ids.splice(index, 1);
        delete this.watchlistEntries[jobId];
        this.events.emit('onRemoveJob', jobId);
        return true;
    }

    /**
     * Add a watchlistEntry to the users watchlist
     * @param {Object} watchlistEntry
     */
    saveWatchlistEntry(watchlistEntry) {
        this.request = new Promise((resolve, reject) => {
            putJson(
                `${API_ENDPOINT_WATCHLIST_MEMO_UPDATE}/${watchlistEntry.jobId}`,
                { data: watchlistEntry },
                (response) => { this.onWatchlistEntryResponse(response); resolve(response); },
                (error) => { this.onWatchlistEntryResponseError(error); reject(error); },
            );
        });
        return this.request;
    }

    /**
     * Check if a job is already on the users watchlist
     * @return {boolean}
     */
    isWatched(jobId) {
        if (!Array.isArray(this.ids)) return false;
        return this.ids.indexOf(jobId) > -1;
    }

    /**
     * If we get a watchlistEntry we put it in this.watchlistEntries
     * @emits {onWatchlistEntryResponse} - tell listening modules to update the watchlistEntry
     */
    onWatchlistEntryResponse(response) {
        this.watchlistEntries[response.jobId] = {
            watchlistId: response.watchlistId,
            memo: response.memo,
        };
        this.events.emit('onWatchlistEntryResponse');
    }

    /**
     * if something is broken with saving the watchlistEntry
     * @emits {onWatchlistEntryResponseError}
     */
    onWatchlistEntryResponseError(error) {
        this.events.emit('onWatchlistEntryResponseError', error);
    }

    /**
     * Get WatchlistEntry by jobId
     * @param {number} jobId
     * @return {Object} watchlistEntry
     */
    getWatchlistEntry(jobId) {
        if (!Array.isArray(this.watchlistEntries)) return false;
        return this.watchlistEntries[jobId];
    }
}

export const userWatchlist = new UserWatchlist();
app.userWatchlist = userWatchlist;
