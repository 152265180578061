import { AppModule } from 'app/module';

import Template from './template.hbs';
import './styles.scss';

export class Snackbar extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        return {
            actionLink: '.m-snackbar__link--action',
        };
    }

    getPropsFromDom() {
        return {
            display: false,
            animationDuration: this.dom.el.getAttribute('data-animation-duration'),
        };
    }

    domEvents() {
        if (this.dom.actionLink) {
            this.dom.actionLink.addEventListener('click', (e) => {
                if (e) e.preventDefault();
                this.events.emit('undo', this.props.value, this.props.dislikeId, this.props.company);
            });
        }
    }
}
