import { findModuleByDomElement } from 'util/components';
import { parseRule } from 'util/validate';

export const ModuleValidation = (module) => ({
    componentValidationRules: [],
    inputsToValidate: [],
    validationMessages: {},
    unregisteredInputs: [],

    /**
     * Gets called before validation rules are evaluated.
     * Use for example for advanced tracking purposes.
     * Set via module.validation.before(callback)
     */
    preValidationCallback: () => {},

    /**
     * Gets called after validation has run through.
     * Validated components have errors in their props at this point.
     * Use for example for advanced tracking purposes
     * Set via module.validation.after(callback)
     * @param {Object} [obj]
     */
    postValidationCallback: ({ valid, fromBackend }) => ({ valid, fromBackend }),

    /**
     * @param {AppModule|ValidatableInput} target - The target of the validation.
     * Either a sub component or the component itself
     * @param {Array|Object} validationConfig - configuration for the validation.
     * @returns {Object}
     */
    addRule(target, validationConfig) {
        validationConfig.registeredValidationMessages = this.validationMessages;
        if (target && target !== module) {
            // eslint-disable-next-line no-console, max-len
            if (typeof target.validationSetup !== 'function') return console.warn('The validation target', target, 'does not contain a validationSetup method. Make sure it extends "ValidatableInput"');
            target.validationSetup(validationConfig);
            this.inputsToValidate.push(target);
            return target;
        }
        if (target || target === null) {
            // Overwrite existing validationRules with the same name
            // eslint-disable-next-line max-len
            this.componentValidationRules = this.componentValidationRules.filter(({ name }) => name !== validationConfig.name);
            this.componentValidationRules.push(validationConfig);
            return { target, config: validationConfig };
        }
        return null;
    },

    /** Execute all validations (inputs + rules) */
    validate() {
        this.preValidationCallback();
        let errors;
        errors = this.validateInputs();
        errors = errors.concat(this.validateRules());
        this.postValidationCallback({ valid: errors.length === 0, fromBackend: false });
        return errors.length === 0;
    },

    /** Validate registered inputs (sub components) */
    validateInputs() {
        const errors = [];
        // Kick out inputs that are no longer part of the current dom
        this.inputsToValidate = this.inputsToValidate.filter((input) => document.contains(input.dom.el));
        this.inputsToValidate.forEach((input) => {
            const inputErrors = input.validate();
            if (inputErrors.length > 0) errors.push(inputErrors);
        });
        return errors;
    },

    /** Validate registered rules on component layer */
    validateRules() {
        const errors = [];
        this.componentValidationRules.forEach((rule) => {
            const ruleErrors = [];
            // eslint-disable-next-line one-var-declaration-per-line, one-var
            let name, test, message, sideEffects, specificName, ruleValue, validationMessageOutlet;
            // eslint-disable-next-line prefer-const
            ({ sideEffects, name, message, test, validationMessageOutlet } = rule);

            // eslint-disable-next-line prefer-const
            ({ specificName, name, value: ruleValue } = parseRule(name));
            if (!test) return;

            // Check for message pool
            if (!message && this.validationMessages) {
                message = this.validationMessages?.[specificName];
            }

            message = typeof message === 'function' ? message(ruleValue) : message;

            if (!test()) ruleErrors.push({ [name]: true, message });
            validationMessageOutlet?.updateProps({ errors: ruleErrors }, true);
            if (ruleErrors.length) {
                errors.push(ruleErrors);
            }
            if (sideEffects) sideEffects({ name, valid: ruleErrors.length === 0 });
        });
        return errors;
    },

    useMessages(messageObject) {
        this.validationMessages = messageObject;
    },

    // Set registered inputs + unregistered ones we found before valid;
    setAllInputsValid() {
        this.inputsToValidate.forEach((input) => input.setValid());

        this.unregisteredInputs.forEach((key) => {
            const domInput = module.dom.el.querySelector(`[name=${key}]`);
            const moduleInputBelongsTo = findModuleByDomElement(domInput, module);
            moduleInputBelongsTo.setValid?.();
            delete this.unregisteredInputs[key];
        });
    },

    handleBackendValidationErrors(response) {
        // Set all inputs to valid by default;
        this.setAllInputsValid();

        if (!response || !response.errors) return false;

        this.preValidationCallback({ fromBackend: true });

        const errorsMeta = {};
        response.errors.forEach(({ meta, status }) => {
            if (meta && String(status).startsWith('4')) {
                Object.keys(meta).forEach((key) => {
                    errorsMeta[key] = meta[key];
                });
            }
        });

        const unprocessedErrors = { ...errorsMeta };

        // Set inputs with errors to invalid. Base errors off input names.
        this.assignErrorsToRegisteredInputs(errorsMeta, unprocessedErrors);

        // Find inputs for errors not picked up by validation rules
        this.findInputsForUnprocessedErrors(unprocessedErrors);

        // Handle component validation rules
        this.handleComponentValidationRules(errorsMeta);

        // Callbacks + return values
        this.postValidationCallback({ valid: false, fromBackend: true });

        return errorsMeta;
    },

    assignErrorsToRegisteredInputs(errorsMeta, unprocessedErrors) {
        this.inputsToValidate
            .filter((input) => document.contains(input.dom.el)) // Kick inputs that are no longer part of the DOM
            .forEach((input) => {
                const name = input.dom.input?.name || input.dom.hiddenInput?.name;
                if (!errorsMeta[name]) return;

                delete unprocessedErrors[name];
                const relevantErrors = [...errorsMeta[name]];

                // Get relevant errors as an array of keys
                const errorKeys = Object.keys(...errorsMeta[name]);
                // Inject error messages into the error object, via registered messages or custom messages from rules
                errorKeys.forEach((errorKey) => {
                    // Check if there's a rule relevant to this error key
                    const relevantRule = (input.internalValidationRules ?? [])
                        .find((rule) => parseRule(rule.name || rule).name === errorKey);

                    const index = errorKeys.findIndex((err) => err === errorKey);
                    if (!errorKeys[index]) return;

                    // Check registered validation messages for a relevant key and inject its value as error message
                    Object.keys(this.validationMessages ?? {})
                        .filter((value) => errorKeys.includes(value))
                        .forEach((registered) => {
                            relevantErrors[index].message = typeof this.validationMessages[registered] === 'function'
                                ? this.validationMessages[registered](relevantErrors[index][errorKey])
                                : this.validationMessages[registered];
                        });

                    // Custom messages always take precedence
                    if (relevantRule?.message) {
                        relevantErrors[index].message = typeof relevantRule.message === 'function'
                            ? relevantRule.message(relevantErrors[index][errorKey])
                            : relevantRule.message;
                    }
                });
                input.setInvalid(relevantErrors);
            });
    },

    findInputsForUnprocessedErrors(unprocessedErrors) {
        (Object.keys(unprocessedErrors) ?? []).forEach((key) => {
            // Find the dom element the BE error presumably belongs to
            const domInput = module.dom.el.querySelector(`[name=${key}]`);
            if (domInput && !this.unregisteredInputs[key]) this.unregisteredInputs.push(key);

            // Find the module the dom element belongs to
            const moduleInputBelongsTo = findModuleByDomElement(domInput, module);

            // Make sure the module the dom elements belongs to is validatable
            if (!(typeof moduleInputBelongsTo.setInvalid === 'function')) return;

            const inputErrors = unprocessedErrors[key];

            // Inject registered messages
            const errorKeys = Object.keys(...inputErrors);
            Object.keys(this.validationMessages ?? {})
                .filter((value) => errorKeys.includes(value))
                .forEach((registered) => {
                    const index = inputErrors.findIndex((err) => Object.keys(err)[0] === registered);
                    if (index !== -1) {
                        const errorValue = inputErrors[index][Object.keys(inputErrors[index])[0]];
                        inputErrors[index].message = typeof this.validationMessages[registered] === 'function'
                            ? this.validationMessages[registered](errorValue)
                            : this.validationMessages[registered];
                    }
                });

            moduleInputBelongsTo.setInvalid(inputErrors);
        });
    },

    handleComponentValidationRules(errorsMeta) {
        // Expectation: Component level validation errors are always in the 'general' key of the error meta property
        let potentialComponentErrors = errorsMeta.general;
        if (!potentialComponentErrors) return;

        // Sometimes general errors are sent within an array, sometimes as object.
        // Until backend decides which they like more, we make a security check and cast appropriately.
        if (!Array.isArray(potentialComponentErrors)) potentialComponentErrors = [potentialComponentErrors];

        const errorKeys = Object.keys(...potentialComponentErrors);

        this.componentValidationRules.forEach((rule) => {
            // If there is no error key the same as our rule name, the rule is valid and we can stop
            const { name } = rule;

            const key = errorKeys.find((needle) => name === needle);
            const index = errorKeys.findIndex((needle) => name === needle);
            if (!key) return;

            let { message } = rule;

            // Set validation message if none is supplied by the rule
            if (!message) message = this.validationMessages[name];

            // If message is a function, call it with the error value
            if (typeof message === 'function') {
                const errorValue = potentialComponentErrors[index][Object.keys(potentialComponentErrors[index])[0]];
                message = message(errorValue);
            }

            rule.validationMessageOutlet?.updateProps({
                errors: [{ [name]: true, message }],
            }, true);
        });
    },

    /**
     * Sets the pre-validation callback.
     */
    before(fn) { this.preValidationCallback = fn; },

    /**
     * Set the post-validation callback.
     * Validated components have errors in their props at this point.
     * @param {Object} [obj]
     */
    after(fn) { this.postValidationCallback = fn; },
});
