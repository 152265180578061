import { AppModule } from 'app/module';
import { TIME_TOAST_TIMEOUT } from 'app/timings';

import Template from './template.hbs';
import './styles.scss';

export class Toast extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        return {
            inner: '.m-toast__inner',
            message: '.m-toast__message',
        };
    }

    getPropsFromDom() {
        return {
            active: this.dom.inner !== false,
            message: this.dom.message.innerHTML,
        };
    }

    ready() {
        if (this.props.active) {
            this.slideIn();
        }
    }

    slideIn() {
        setTimeout(() => {
            this.dom.inner.classList.add('m-toast__inner--visible');
        }, 1000);

        setTimeout(() => {
            this.slideOut();
        }, TIME_TOAST_TIMEOUT);
    }

    slideOut() {
        this.dom.inner.classList.remove('m-toast__inner--visible');
    }
}
