import { AppModule } from 'app/module';
import Template from './template.hbs';
import './styles.scss';

export class HeaderSmall extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        return {
            back: '.m-headerSmall__back',
        };
    }
}
