import { DropdownOption } from 'module/dropdownOption/main';
import {
    KEYCODE_DOWN,
    KEYCODE_DOWN_EDGE,
    KEYCODE_ENTER,
    KEYCODE_ESCAPE,
    KEYCODE_ESCAPE_EDGE,
    KEYCODE_TABULATOR,
    KEYCODE_UP,
    KEYCODE_UP_EDGE,
} from 'app/keyCodes';
import 'module/icon/main';
import './styles.scss';
import { ValidatableInput } from 'app/validatableInput';
import Template from './template.hbs';

let focusedItemIndex;

export class Dropdown extends ValidatableInput {
    setTemplate() {
        this.template = Template;
    }

    domBindings() {
        this.dom.button = this.dom.el.querySelector('.m-dropdown__button');
        this.dom.text = this.dom.el.querySelector('.k-c-dropdown__text');
        this.dom.label = this.dom.el.querySelector('.m-dropdown__label');
        this.dom.list = this.dom.el.querySelector('.m-dropdown__list');
        this.dom.hiddenInput = this.dom.el.querySelector('.m-dropdown__hiddenInput');
        this.dom.options = this.dom.el.querySelectorAll('.m-dropdownOption');
        this.dom.validationMessageOutlet = this.dom.el
            .parentElement.querySelector('.m-dropdown__validationMessageOutlet');

        if (this.dom.options) {
            this.options = [];
            const optionsProps = [];
            for (let i = 0; i < this.dom.options.length; i += 1) {
                this.options[i] = new DropdownOption(this.dom.options[i]);
                optionsProps.push(this.options[i].getPropsFromDom());
            }
            this.props.options = optionsProps;
        }
    }

    getPropsFromDom() {
        return {
            touched: this.dom.button.classList.contains('m-dropdown__button--touched'),
            open: this.dom.list.classList.contains('k-c-dropdown__list--open'),
            text: this.dom.text.innerHTML,
            name: this.dom.hiddenInput.name,
            label: this.dom.label ? this.dom.label.innerText : '',
            error: this.dom.button.classList.contains('m-dropdown__button--error'),
            // save in props for salaryBenchmarkForm BE validation
            errors: this.validationMessage?.getPropsFromDom().errors,
            className: this.dom.el.getAttribute('data-class'),
            disabled: this.dom.button.disabled,
            currentValue: this.dom.hiddenInput.value,
            options: this.getOptionProps(),
            selectedIndex: parseInt(this.dom.text.getAttribute('data-selected-index'), 10),
            placeholder: this.dom.text.classList.contains('m-dropdown__text--placeholder'),
            isIE: 'objectFit' in document.documentElement.style === false,
        };
    }

    getOptionProps() {
        const optionsProps = [];
        if (this.options) {
            for (let i = 0; i < this.options.length; i += 1) {
                optionsProps.push(this.options[i].getPropsFromDom());
            }
        }
        return optionsProps;
    }

    ready() {
        focusedItemIndex = this.props.selectedIndex;
    }

    domEvents() {
        this.dom.button.addEventListener('click', () => {
            this.toggleDropdown();
        });

        this.dom.el.addEventListener('keydown', (e) => this.pressKey(e, e.key));
        this.dom.text.addEventListener('change', (event) => this.events.emit('change', event));
        document.addEventListener('click', (e) => this.handleClickOutside(e));
    }

    pressKey(e, keyCode) {
        if (keyCode !== KEYCODE_TABULATOR) {
            // Stops page from scrolling
            e.preventDefault();
        }
        if ((keyCode === KEYCODE_ENTER || keyCode === KEYCODE_DOWN || keyCode === KEYCODE_DOWN_EDGE)
            && !this.props.open && !this.props.disabled) {
            this.toggleDropdown();
        } else if ((keyCode === KEYCODE_DOWN || keyCode === KEYCODE_DOWN_EDGE) && this.props.open) {
            this.selectNextItem();
        } else if ((keyCode === KEYCODE_UP || keyCode === KEYCODE_UP_EDGE) && this.props.open) {
            this.selectPreviousItem();
        } else if ((keyCode === KEYCODE_ESCAPE || keyCode === KEYCODE_ESCAPE_EDGE) && this.props.open) {
            this.toggleDropdown();
        } else if (keyCode === KEYCODE_ENTER && this.props.open) {
            this.selectItemByIndex(focusedItemIndex);
        }
    }

    selectNextItem() {
        if (this.dom.options.length > 0) {
            let newIndex = focusedItemIndex + 1;
            // if last select first or if first time pressed jump to first item
            if (newIndex > this.dom.options.length - 1 || focusedItemIndex === null) {
                newIndex = 0;
            }
            this.dom.options[newIndex].focus();
            if (!this.props.isIE) {
                this.dom.options[newIndex].scrollIntoView(
                    {
                        behavior: 'auto',
                        block: 'nearest',
                    },
                );
            }

            focusedItemIndex = newIndex;
        }
    }

    selectPreviousItem() {
        if (this.dom.options.length > 0) {
            let newIndex = focusedItemIndex - 1;
            if (newIndex < 0) {
                newIndex = this.dom.options.length - 1;
            }
            this.dom.options[newIndex].focus();
            if (!this.props.isIE) {
                this.dom.options[newIndex].scrollIntoView(
                    {
                        behavior: 'auto',
                        block: 'nearest',
                    },
                );
            }
            focusedItemIndex = newIndex;
        }
    }

    selectItemByIndex(index) {
        const updatedItems = this.props.options;
        const oldSelectedIndex = updatedItems[index]?.selected;

        // remove old selected
        for (let i = 0; i < updatedItems.length; i += 1) {
            if (i === index) {
                updatedItems[i].selected = true;
            } else {
                updatedItems[i].selected = false;
            }
        }

        if (index === null) {
            this.updateProps({
                open: false,
            }, true);
            this.dom.button.focus();
            return;
        }

        this.updateProps({
            items: updatedItems,
            selectedIndex: index,
            text: this.dom.options[index].innerHTML,
            currentValue: this.dom.options[index].getAttribute('data-value'),
            open: false,
            placeholder: false,
        }, true);

        if (oldSelectedIndex !== updatedItems[index].selected) {
            this.events.emit('change');
        }
        this.dom.button.focus();
    }

    handleClickOutside(e) {
        if (e.target.classList) {
            const outsideDropdown = !e.target.classList.contains('k-c-dropdown__text')
                && !e.target.classList.contains('m-dropdown__button')
                && !e.target.classList.contains('k-c-dropdown__toggle')
                && !e.target.classList.contains('k-c-dropdown__listItem');

            if (this.props.open && outsideDropdown) {
                this.events.emit('closeAllDropdowns', e);
            }
        }
    }

    subEvents() {
        for (let i = 0; i < this.options.length; i += 1) {
            this.options[i].events.on('setValue', (e) => {
                this.setOption(e);
                this.selectItemByIndex(i);
            });
        }
    }

    toggleDropdown() {
        if (!this.props.open) {
            this.events.emit('closeAllDropdowns');
        }

        this.updateProps({
            touched: true,
            open: !this.props.open,
        }, true);
        focusedItemIndex = this.props.selectedIndex;
        this.dom.button.focus();
    }

    setOption(data) {
        const optionData = data.arguments[0];
        this.updateProps({
            open: false,
            currentValue: optionData.value,
            text: optionData.name,
            placeholder: false,
        }, true);
    }

    resetOptions(disabled = false) {
        const options = this.getOptionProps();
        for (let i = 0; i < options.length; i += 1) {
            options[i].selected = false;
        }
        this.updateProps({
            open: false,
            text: 'Umkreis',
            currentValue: '',
            placeholder: true,
            options,
            disabled,
        }, true);
    }

    getValue() {
        return this.dom.hiddenInput.value;
    }

    closeDropdown() {
        this.updateProps({ open: false }, true);
    }

    enableDropdown() {
        this.updateProps({ disabled: false }, true);
    }
}
