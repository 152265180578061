var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " m-jobFieldsListing__list--showMore";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <li class=\"m-jobFieldsListing__listItem\">\n"
    + ((stack1 = container.invokePartial(require("../jobFieldsListingItem/template.hbs"),depth0,{"name":"module/jobFieldsListingItem/template","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "            Weniger Berufsfelder anzeigen\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "            Mehr Berufsfelder anzeigen\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"m-jobFieldsListing\">\n    <div class=\"m-jobFieldsListing__pretext\">Jobs in diesen Bereichen finden</div>\n    <ul class=\"m-jobFieldsListing__list"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showMore") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":39},"end":{"line":3,"column":97}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"jobFieldItems") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":17}}})) != null ? stack1 : "")
    + "    </ul>\n    <button class=\"m-jobFieldsListing__showMoreButton\" type=\"button\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showMore") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":15,"column":15}}})) != null ? stack1 : "")
    + "    </button>\n</div>\n";
},"usePartial":true,"useData":true});