import { AppModule } from 'app/module';
import { JobSearchHistory } from 'util/client-side-storage/jobSearchHistory';
import { ENDPOINT_JOBS } from 'app/endpoints';
import './styles.scss';
import Template from './template.hbs';

const maxRecentSearchesToDisplay = 3;

export class RecentSearchesList extends AppModule {
    setTemplate() {
        this.template = Template;
    }

    async ready() {
        const items = await this.getCurrentItems();

        this.updateProps({ items, hasSearches: items.length > 0 }, true);
    }

    onResponseError() {
        const items = this.props.items.map((item) => ({ ...item, count: null }));
        this.updateProps({ items }, true);
    }

    domBindings() {
        return {
            items: ['.m-recentSearchesList__item'],
            itemsRemove: ['.m-recentSearchesList__itemRemove'],
        };
    }

    getPropsFromDom() {
        const items = this.dom.items.map((item) => ({
            label: item.querySelector('.m-recentSearchesList__labelText').innerText ?? '',
        }));

        return {
            items,
            hasSearches: this.dom.items.length > 0,
        };
    }

    domEvents() {
        if (this.dom.itemsRemove) {
            this.dom.itemsRemove.forEach((itemRemove) => {
                itemRemove.addEventListener('click', async () => {
                    const keyword = itemRemove.getAttribute('data-keyword');
                    const location = itemRemove.getAttribute('data-location');

                    await JobSearchHistory.removeCombinationFromHistory({ keyword, location });

                    const items = await this.getCurrentItems();

                    this.events.emit('rerenderRecentSearches', { items, hasSearches: items.length > 0 });
                });
            });
        }
    }

    async getCurrentItems() {
        const items = (await JobSearchHistory.getCombinationArray())
            .map(({ keyword, location }) => {
                const isCombination = keyword && location;
                const label = `${keyword}${isCombination ? ' in ' : ''}${location}`;
                const link = `${ENDPOINT_JOBS}${keyword ? `/${keyword}` : ''}${location ? `/${location}` : ''}`;

                return {
                    label,
                    link,
                    keyword,
                    location,
                };
            })
            .slice(0, maxRecentSearchesToDisplay);

        return items;
    }
}
