import { AppModule } from 'app/module';
import { historyApi } from 'util/detect';
import { sendPageView } from 'util/googleAnalytics';

/**
 * Base Controller class - AppModule with html5 history
 * @extends AppModule
 */
export class AppController extends AppModule {
    /**
     * Boot controller and expose it for the se app
     */
    boot() {
        super.boot();
        return this;
    }

    /**
     * Replace state and listen to popstate events
     */
    initHistoryApi() {
        // @todo this is broken if we dont use history api
        if (historyApi()) {
            // pass everything because ie11 :(
            this.replaceState(this.getProps(), document.title, window.location.href);
            window.addEventListener('popstate', (e) => this.popStateEvent(e));
        }
    }

    /**
     * Fires if browser navigates in history
     * This wrapper sets the title (because WTF browsers ...)
     * and calls the controllers onPopState Method
     * @param {Event} popStateEvent - the popstate
     */
    popStateEvent(e) {
        const { state } = e;

        this.onPopState(state);

        if (state && state.title) {
            document.title = state.title;
        }
    }

    /**
     * Do controller stuff on popstate
     * @param {State} EventState - The state we get from the history event
     * @abstract
     */
    // eslint-disable-next-line
    onPopState(state) {}

    /**
     * Push a new state to the browser history (i.e on ajax search)
     * @param {Object} state - the controller props @todo is this needed?
     * @param {String} title - the window title
     * @param {String} url - the new url
     * @param {Boolean} sendPV - if pageView will be sent
     */
    pushState(state, title, url, sendPV = true) {
        if (historyApi()) {
            window.history.pushState(state, title, url);
            document.title = title;
            if (sendPV) sendPageView(title, url);
        } else {
            window.location.href = url;
        }
    }

    /**
     * Replaces the current item in the history stack
     * Used on inital request {@link AppController.boot}
     * This makes the controller state and title accessible when reading from
     * history events.
     *
     * @param {Object} state - the controller props @todo is this needed?
     * @param {String} title - the window title
     * @param {String} url - the new url
     */
    replaceState(state, title, url) {
        window.history.replaceState(state, title, url);

        if (title) document.title = title;
    }
}
